import React from "react";
import { ITicketRelease } from "../../../types";
import { Box, Typography } from "@mui/joy";
import usePalette from "../../../theme/use_palette";
import TicketReleaseCountdown from "./tr_countdown";
import { useTranslation } from "react-i18next";
import StyledText from "../../text/styled_text";

const TicketReleaseSoldOut: React.FC<{
  ticketRelease: ITicketRelease;
}> = ({ ticketRelease }) => {
  const { t } = useTranslation();
  const palette = usePalette();
  return (
    <>
      <Box
        mt={1}
        mb={4}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <StyledText
          level="button"
          fontSize={32}
          fontWeight={700}
          color={palette.errorMain}
          sx={{
            textTransform: "uppercase",
            transform: "rotate(-5deg)",
          }}
        >
          {t("event.ticket_release.sold_out")}
        </StyledText>
      </Box>
    </>
  );
};

export default TicketReleaseSoldOut;