import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchMerchantCustomizationFailure,
  fetchMerchantCustomizationStart,
  fetchMerchantCustomizationSuccess,
  updateMerchantCustomizationFailure,
  updateMerchantCustomizationStart,
  updateMerchantCustomizationSuccess,
} from "../../../features/manager/merchant/merchantCustomizationSlice";
import {
  ApiResponse,
  fetchApi,
  putApi,
  uploadFileApi,
} from "../../../../utils/api/api";
import { IMerchantCustomization } from "../../../../types";
import ApiRoutes from "../../../../routes/backend_routes";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Worker Saga: Fetch Merchant Customization
function* fetchMerchantCustomizationSaga(): Generator<any, void, any> {
  try {
    const url = ApiRoutes.generateRoute(
      ApiRoutes.MANAGER_MERCHANT_CUSTOMIZATION,
      {}
    );
    const response: ApiResponse<{
      merchant_customization: IMerchantCustomization;
    }> = yield call(fetchApi, url, true, true);
    yield put(
      fetchMerchantCustomizationSuccess(response.data.merchant_customization)
    );
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || error.message;
    yield put(
      fetchMerchantCustomizationFailure({
        status: error.response?.status,
        message: errorMessage,
      })
    );
  }
}

// Worker Saga: Update Merchant Customization
function* updateMerchantCustomizationSaga(
  action: PayloadAction<{
    data: IMerchantCustomization;
    logoFile?: File | null;
    iconFile?: File | null;
  }>
): Generator<any, void, any> {
  try {
    const { data, logoFile, iconFile } = action.payload;

    let logoUrl = "";
    let iconUrl = "";

    // If logo file is provided, upload the logo first
    if (logoFile) {
      logoUrl = yield call(
        uploadFileApi,
        ApiRoutes.MANAGER_MERCHANT_CUSTOMIZATION_LOGO,
        logoFile,
        (progress) => {}
      );

      if (!logoUrl) {
        toast.error("Failed to upload logo");
        return; // Exit if logo upload fails
      }
    }

    // If icon file is provided, upload the icon next
    if (iconFile) {
      iconUrl = yield call(
        uploadFileApi,
        ApiRoutes.MANAGER_MERCHANT_CUSTOMIZATION_ICON,
        iconFile,
        (progress) => {}
      );

      if (!iconUrl) {
        toast.error("Failed to upload icon");
        return; // Exit if icon upload fails
      }
    }

    // After both uploads (if applicable), submit the customization form
    const url = ApiRoutes.generateRoute(
      ApiRoutes.MANAGER_MERCHANT_CUSTOMIZATION,
      {}
    );

    delete data.logoUrl;
    delete data.iconUrl;

    const response: ApiResponse<{
      merchant_customization: IMerchantCustomization;
    }> = yield call(putApi, url, data, true, true);

    toast.success("Customization updated successfully");
    yield put(
      updateMerchantCustomizationSuccess(response.data.merchant_customization)
    );
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || error.message;
    yield put(
      updateMerchantCustomizationFailure({
        status: error.response?.status,
        message: errorMessage,
      })
    );
  }
}

// Watcher Saga: Watches for dispatched actions
function* merchantCustomizationSaga() {
  yield takeLatest(
    fetchMerchantCustomizationStart.type,
    fetchMerchantCustomizationSaga
  );
  yield takeLatest(
    updateMerchantCustomizationStart.type,
    updateMerchantCustomizationSaga
  );
}

export default merchantCustomizationSaga;
