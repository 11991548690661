import React from "react";
import { ITicketRelease } from "../../../types";
import { Typography } from "@mui/joy";
import usePalette from "../../../theme/use_palette";

const TicketReleaseHasClosed: React.FC<{
  ticketRelease: ITicketRelease;
}> = ({ ticketRelease }) => {
  const palette = usePalette();
  return (
    <Typography
      level="body-sm"
      fontFamily={"Libre Franklin"}
      fontSize={18}
      mt={1}
      fontWeight={700}
      style={{
        color: palette.charcoal,
      }}
    >
      Ticket release has closed
    </Typography>
  );
};

export default TicketReleaseHasClosed;
