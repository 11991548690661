import { Box, Divider, Grid, Stack } from "@mui/joy";
import { IAddon, ITicket, ITicketAddon, ITicketOrder } from "../../types";
import StyledText from "../text/styled_text";
import usePalette from "../../theme/use_palette";
import { useCosts } from "../events/payments/use_cost";
import { useTranslation } from "react-i18next";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface ShowTicketOrdersTicketsProps {
  ticketOrder: ITicketOrder;
}

const ShowTicketOrdersTickets: React.FC<ShowTicketOrdersTicketsProps> = ({
  ticketOrder,
}) => {
  const { totalTicketCost, totalAddonsCost, totalCost } = useCosts(ticketOrder);
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <Box
      mt={2}
      sx={{
        width: "500px",
      }}
    >
      <StyledText
        level="body-sm"
        fontSize={22}
        color={palette.textSecondary}
        fontWeight={600}
      >
        {t("ticket_request.cost_overview")}
      </StyledText>

      {/* Loop through each ticket in the order */}
      <>
        {ticketOrder.tickets.map((ticket, index) => {
          const ticketType = ticket.ticket_type;
          const ticketPrice = ticketType?.price || 0;

          return (
            <Box key={ticket.id}>
              {/* Render each individual ticket */}
              <Grid
                container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  container
                  justifyContent={"flex-start"}
                  flexDirection={"row"}
                >
                  <LocalActivityIcon />
                  <StyledText
                    level="body-sm"
                    color={palette.charcoal}
                    fontSize={18}
                    style={{
                      marginLeft: "8px",
                    }}
                  >
                    1 x {ticketType?.name || "Ticket"} {/* Show ticket name */}
                  </StyledText>
                </Grid>
                <StyledText
                  level="body-sm"
                  color={palette.charcoal}
                  fontSize={18}
                >
                  SEK {ticketPrice.toFixed(2)} {/* Show ticket price */}
                </StyledText>
              </Grid>

              {/* Render addons for this specific ticket */}
              {ticket.ticket_add_ons!.length > 0 && (
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  {ticket.ticket_add_ons?.map((addon: ITicketAddon) => {
                    const addonDetails: IAddon | undefined =
                      ticketOrder.ticket_release?.add_ons?.find(
                        (a: IAddon) => a.id === addon.add_on_id
                      );

                    return (
                      <Grid
                        key={`addon-${addon.id}`}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Stack
                          direction={"row"}
                          sx={{
                            ml: 2,
                          }}
                        >
                          <AddCircleOutlineIcon sx={{ mr: 2 }} />
                          <StyledText
                            level="body-sm"
                            color={palette.charcoal}
                            fontSize={14}
                          >
                            {addon.quantity} x {addonDetails?.name || "Addon"}{" "}
                            {/* Show addon name */}
                          </StyledText>
                        </Stack>
                        <StyledText
                          level="body-sm"
                          color={palette.charcoal}
                          fontSize={14}
                        >
                          SEK{" "}
                          {(
                            (addonDetails?.price || 0) * addon.quantity
                          ).toFixed(2)}{" "}
                          {/* Show addon price */}
                        </StyledText>
                      </Grid>
                    );
                  })}
                </Box>
              )}
            </Box>
          );
        })}

        <Divider />

        {/* Total */}
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledText
            level="body-sm"
            color={palette.charcoal}
            fontSize={18}
            style={{
              fontWeight: "bold",
            }}
          >
            {t("event.ticket_release.checkout.total")}
          </StyledText>
          <StyledText
            level="body-sm"
            color={palette.charcoal}
            fontSize={18}
            style={{
              fontWeight: "bold",
            }}
          >
            SEK {totalCost.toFixed(2)} {/* Show total cost */}
          </StyledText>
        </Grid>
      </>
    </Box>
  );
};

export default ShowTicketOrdersTickets;
