// src/redux/features/formFieldsSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FormFieldsState,
  IEventFormField,
  IEventFormFieldInput,
  IGuestCustomer,
} from "../../types";

const initialState: FormFieldsState = {
  formFields: [],
  loading: false,
  error: null,
};

const formFieldsSlice = createSlice({
  name: "formFields",
  initialState,
  reducers: {
    // Fetch Form Fields
    fetchFormFieldsRequest: (
      state,
      action: PayloadAction<{ eventId: number }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    fetchFormFieldsSuccess: (
      state,
      action: PayloadAction<IEventFormField[]>
    ) => {
      state.loading = false;
      state.formFields = action.payload;
    },
    fetchFormFieldsFailure: (
      state,
      action: PayloadAction<{ error: string }>
    ) => {
      state.loading = false;
      state.error = action.payload.error;
    },

    // Update Form Fields
    updateFormFieldsRequest: (
      state,
      action: PayloadAction<{
        eventId: number;
        formFields: IEventFormFieldInput;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    updateFormFieldsSuccess: (
      state,
      action: PayloadAction<IEventFormField[]>
    ) => {
      state.loading = false;
      state.formFields = action.payload;
    },
    updateFormFieldsFailure: (
      state,
      action: PayloadAction<{ error: string }>
    ) => {
      state.loading = false;
      state.error = action.payload.error;
    },

    // Submit Form Field Response
    submitFormFieldResponseRequest: (
      state,
      action: PayloadAction<{
        formFieldValues: { event_form_field_id: number; value: string }[];
        event_id: number;
        ticketId: number;
        isGuestCustomer?: boolean;
        guestCustomer?: IGuestCustomer;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    submitFormFieldResponseSuccess: (state) => {
      state.loading = false;
      // Optionally update state if needed
    },
    submitFormFieldResponseFailure: (
      state,
      action: PayloadAction<{ error: string }>
    ) => {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  fetchFormFieldsRequest,
  fetchFormFieldsSuccess,
  fetchFormFieldsFailure,
  updateFormFieldsRequest,
  updateFormFieldsSuccess,
  updateFormFieldsFailure,
  submitFormFieldResponseRequest,
  submitFormFieldResponseSuccess,
  submitFormFieldResponseFailure,
} = formFieldsSlice.actions;

export default formFieldsSlice.reducer;
