import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import usePinnedDrawer from "../../hooks/drawer_pinned_hook";
import DrawerComponent from "../navigation/manage_drawer/event_detail";
import ManagerDashboardDrawerComponent from "../navigation/manage_drawer/manager_dashboard";

interface DrawerBoxWrapperProps {
  eventID?: string;
  children?: React.ReactNode;
  showManagerDashboard?: boolean;
}

const DrawerBoxWrapper: React.FC<DrawerBoxWrapperProps> = ({
  eventID,
  children,
  showManagerDashboard = false,
}) => {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

  // Custom hook for pinned drawer behavior, only used on larger screens
  const { marginLeft, isPinned, handlePinned } = usePinnedDrawer("75px");

  return showManagerDashboard ? (
    <>
      <ManagerDashboardDrawerComponent handlePinned={handlePinned} />
      <Box
        component={"main"}
        sx={{
          // On small screens, marginLeft is not used, set margins appropriately
          ml: isScreenSmall ? 1.5 : marginLeft,
          mr: 1.5,
        }}
      >
        {children}
      </Box>
    </>
  ) : (
    <>
      <DrawerComponent eventID={eventID!} handlePinned={handlePinned} />
      <Box
        component={"main"}
        sx={{
          // On small screens, marginLeft is not used, set margins appropriately
          ml: isScreenSmall ? 1.5 : marginLeft,
          mr: 1.5,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default DrawerBoxWrapper;
