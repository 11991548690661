// src/redux/sagas/formFieldsSaga.ts

import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchFormFieldsRequest,
  fetchFormFieldsSuccess,
  fetchFormFieldsFailure,
  updateFormFieldsRequest,
  updateFormFieldsSuccess,
  updateFormFieldsFailure,
  submitFormFieldResponseRequest,
  submitFormFieldResponseSuccess,
  submitFormFieldResponseFailure,
} from "../features/formFieldsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IEventFormFieldInput,
  IGuestCustomer,
  IEventFormField,
} from "../../types";
import { toast } from "react-toastify";
import { ApiResponse, fetchApi, putApi } from "../../utils/api/api";

// Fetch Form Fields Saga
function* fetchFormFieldsSaga(
  action: PayloadAction<{ eventId: number }>
): Generator<any, void, any> {
  try {
    const { eventId } = action.payload;
    const response: ApiResponse<{
      form_fields: IEventFormField[];
    }> = yield call(fetchApi, `/events/${eventId}/form-fields`, true);

    if (response.status === "success") {
      yield put(fetchFormFieldsSuccess(response.data.form_fields));
    } else {
      const errorMessage = response.message || "Failed to fetch form fields.";
      yield put(fetchFormFieldsFailure({ error: errorMessage }));
      toast.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error ||
      error.message ||
      "Failed to fetch form fields.";
    yield put(fetchFormFieldsFailure({ error: errorMessage }));
    toast.error(errorMessage);
  }
}

// Update Form Fields Saga (Upsert)
function* updateFormFieldsSaga(
  action: PayloadAction<{ eventId: number; formFields: IEventFormFieldInput }>
): Generator<any, void, any> {
  try {
    const { eventId, formFields } = action.payload;
    const response: ApiResponse<{
      form_fields: IEventFormField[];
    }> = yield call(putApi, `/events/${eventId}/form-fields`, formFields, true);

    if (response.status === "success") {
      // Optionally, fetch the updated form fields again after update
      setTimeout(() => {
        toast.success("Form fields updated successfully.");
      }, 500);
      yield put(updateFormFieldsSuccess(response.data.form_fields));
    } else {
      const errorMessage = response.message || "Failed to update form fields.";
      yield put(updateFormFieldsFailure({ error: errorMessage }));
      toast.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = error.message || "Failed to update form fields.";
    yield put(updateFormFieldsFailure({ error: errorMessage }));
    toast.error(errorMessage);
  }
}

// Submit Form Field Response Saga
function* submitFormFieldResponseSaga(
  action: PayloadAction<{
    formFieldValues: { event_form_field_id: number; value: string }[];
    event_id: number;
    ticketId: number;
    isGuestCustomer?: boolean;
    guestCustomer?: IGuestCustomer;
  }>
): Generator<any, void, any> {
  try {
    const {
      formFieldValues,
      event_id,
      ticketId,
      isGuestCustomer,
      guestCustomer,
    } = action.payload;

    // Build the appropriate URL depending on whether the user is a guest
    const url = isGuestCustomer
      ? `/guest-customer/${guestCustomer?.id}/events/${event_id}/tickets/${ticketId}/form-fields?request_token=${guestCustomer?.request_token}`
      : `/events/${event_id}/tickets/${ticketId}/form-fields`;

    const response = yield call(putApi, url, formFieldValues, !isGuestCustomer);

    if (response.status === "success") {
      yield put(submitFormFieldResponseSuccess());
      toast.success("Form field response submitted successfully.");
    } else {
      const errorMessage =
        response.message || "Failed to submit form field response.";
      yield put(submitFormFieldResponseFailure({ error: errorMessage }));
      toast.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage =
      error.message || "Failed to submit form field response.";
    yield put(submitFormFieldResponseFailure({ error: errorMessage }));
    toast.error(errorMessage);
  }
}

// Watcher Saga
function* watchFormFieldsSaga() {
  yield takeLatest(fetchFormFieldsRequest.type, fetchFormFieldsSaga);
  yield takeLatest(updateFormFieldsRequest.type, updateFormFieldsSaga);
  yield takeLatest(
    submitFormFieldResponseRequest.type,
    submitFormFieldResponseSaga
  );
}

export default watchFormFieldsSaga;
