import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import StyledText from "../../text/styled_text";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubButton from "./sub_button";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack } from "@mui/joy";
import RequiredPlanChip from "../../features/required_plan_chip";
import { IPlanEnrollment } from "../../../types";
import {
  getFeaturesPackageTier,
  hasFeatureAccess,
} from "../../../utils/manager/require_feature";
import { useSelector } from "react-redux";
import {
  selectMainColor,
  selectTextColor,
} from "../../../redux/features/managerThemeSlice";
import {
  darkerColor,
  isColorDark,
  lightenColor,
} from "../../../utils/manager/color";
import usePalette, { useIsDarkTheme } from "../../../theme/use_palette";

interface SubItem {
  title: string;
  navigateTo: string;
  clickable: boolean;
  requiredFeature?: string;
}

interface CollapsibleDrawerSectionProps {
  title: string;
  icon: React.ReactNode;
  mainNavigateTo?: string;
  subItems: SubItem[];
  drawerExtended: boolean;
  planEnrollment?: IPlanEnrollment; // From network
  requiredFeature?: string;
  // isSelected?: boolean; // Add isSelected prop to the section itself
  toggleSection: () => void;
  isOpen: boolean;
}

const CollapsibleDrawerSection: React.FC<CollapsibleDrawerSectionProps> = ({
  title,
  icon,
  mainNavigateTo = "",
  subItems,
  drawerExtended,
  planEnrollment,
  requiredFeature,
  isOpen,
  toggleSection,
  // isSelected = false, // Default to false
}) => {
  const [isCollapsing, setIsCollapsing] = useState(false);
  const navigate = useNavigate();
  const palette = usePalette();

  useEffect(() => {
    if (!isOpen && isCollapsing) {
      const timeoutId = setTimeout(() => {
        setIsCollapsing(false);
      }, 1000); // Adjust this value to match the duration of the drawer closing animation

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isOpen, isCollapsing]);

  const handleClick = () => {
    if (mainNavigateTo) {
      navigate(mainNavigateTo);
    }
    if (isOpen) {
      setIsCollapsing(true);
    }

    toggleSection();
  };

  const handleSubItemClick = (event: any) => {
    event.stopPropagation(); // Prevent the click from bubbling up
    // Handle navigation or other actions here
  };

  const featureAccess = requiredFeature
    ? hasFeatureAccess(requiredFeature, planEnrollment)
    : true;

  const textColor = useSelector(selectTextColor);
  const mainColor = useSelector(selectMainColor);
  const darkMode = useIsDarkTheme();

  let selectedBackgroundColor;
  const newColor = isColorDark(mainColor)
    ? `linear-gradient(to right, ${lightenColor(mainColor, 0.2)}, ${mainColor})`
    : `linear-gradient(to right, ${darkerColor(mainColor, 0.8)}, ${mainColor})`;

  selectedBackgroundColor = newColor;

  const isSelected = subItems.some(
    (item) => window.location.pathname === item.navigateTo
  );

  return (
    <ListItem
      disablePadding
      sx={{
        overflow: "hidden",
        background: isSelected ? selectedBackgroundColor : "transparent",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          transition: "all 0.2s ease-in-out",
          backgroundColor: selectedBackgroundColor,
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: 48,
          maxHeight: !isOpen ? 48 : "100%",
        }}
      >
        <ListItemButton
          onClick={handleClick}
          sx={{
            height: 48,
          }}
        >
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            sx={{
              width: "100%",
            }}
          >
            <Box display="flex" alignItems="center">
              <ListItemIcon sx={{ color: textColor }}>{icon}</ListItemIcon>
              <ListItemText>
                <StyledText
                  level="body-md"
                  fontSize={16}
                  fontWeight={600}
                  forceTextPointer={true}
                  color={textColor}
                  sx={{
                    m: 0,
                    p: 0,
                  }}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {title}
                </StyledText>
              </ListItemText>
            </Box>
            <Box>
              {requiredFeature && !featureAccess ? (
                <RequiredPlanChip
                  requiredPlan={
                    getFeaturesPackageTier(
                      requiredFeature,
                      planEnrollment
                    ) as string
                  }
                />
              ) : (
                <ListItemIcon
                  sx={{
                    color: textColor,
                  }}
                >
                  <motion.div
                    animate={{ rotate: isOpen ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <ExpandMoreIcon />
                  </motion.div>
                </ListItemIcon>
              )}
            </Box>
          </Stack>
        </ListItemButton>

        <Collapse
          key={"collapse-section-" + title}
          in={isOpen && drawerExtended && featureAccess}
          sx={{
            pb: 0.5,
          }}
          easing={"ease-in-out"}
          timeout={{ enter: 300, exit: 100 }}
        >
          <List
            component="div"
            sx={{
              height: `${subItems.length * 31}px`,
            }}
          >
            {subItems.map((item) => {
              let subFeatAcess = false;
              let requiredPlan = "";
              if (item.requiredFeature) {
                if (hasFeatureAccess(item.requiredFeature, planEnrollment)) {
                  subFeatAcess = true;
                } else {
                  requiredPlan = getFeaturesPackageTier(
                    item.requiredFeature,
                    planEnrollment
                  ) as string;
                }
              } else {
                subFeatAcess = true;
              }

              return (
                <Stack
                  key={item.title}
                  spacing={1}
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <SubButton
                    key={item.title}
                    title={item.title}
                    clickable={item.clickable}
                    navigateTo={item.navigateTo}
                    hasFeatureAccess={subFeatAcess}
                    requiredPlan={requiredPlan}
                    onClick={handleSubItemClick} // Pass the handler here
                    defaultColor={textColor}
                  />
                </Stack>
              );
            })}
          </List>
        </Collapse>
      </Box>
    </ListItem>
  );
};

export default CollapsibleDrawerSection;
