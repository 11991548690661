import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  SimpleForm,
  SelectInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  useEditContext,
  useRecordContext,
} from "react-admin";
import { INetwork, INetworkAuthProvider } from "../../../types";

export const NetworkAuthProviderList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="type" />
      <ArrayField source="networks">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="created_at" />
      <TextField source="updated_at" />
    </Datagrid>
  </List>
);

const NetworkAuthProviderForm = () => (
  <SimpleForm>
    <SelectInput
      source="type"
      choices={[{ id: "kth", name: "KTH" }]}
      validate={required()}
    />
    <ReferenceArrayInput
      source="id"
      reference="networks"
      validate={required()}
      format={(value) =>
        value?.map((network: INetwork) => network.id ?? network)
      }
      parse={(value) => value}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
  </SimpleForm>
);

export const NetworkAuthProviderEdit = () => (
  <Edit>
    <NetworkAuthProviderForm />
  </Edit>
);

export const NetworkAuthProviderCreate = () => (
  <Create>
    <NetworkAuthProviderForm />
  </Create>
);
