import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNetworkRequest } from "../../redux/features/manager/networkSlice";
import { AppDispatch, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { INetwork, SurfApplicationStatus } from "../../types";
import { setThemeColors } from "../../redux/features/managerThemeSlice";
import { isColorDark } from "../../utils/manager/color";
import usePalette from "../../theme/use_palette";
import { ROUTES } from "../../routes/def";

const useUpdateNetworkColors = (network: INetwork | null | undefined) => {
  const dispatch = useDispatch();
  const palette = usePalette();

  useEffect(() => {
    if (network?.settings) {
      const main_color = network.settings.main_color || palette.primaryMain;
      const accent_color = network.settings.accent_color || palette.accentMain;
      const text_color = network.settings.text_color || palette.charcoal;

      dispatch(
        setThemeColors({
          main_color,
          accent_color,
          text_color,
        })
      );
    }
  }, [network, dispatch, palette]);
};

export const useNetworkDetails = (redirectOnFail: boolean = true) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shouldFetch, setShouldFetch] = useState(true);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { network, loading, error } = useSelector(
    (state: RootState) => state.network
  );

  useUpdateNetworkColors(network);

  useEffect(() => {
    if (error) {
      if (error !== "User does not belong to a network") {
        if (redirectOnFail) {
          setTimeout(() => {
            toast.error(error);
          }, 1000);

          navigate("/");
        }
      }
    }
  }, [error, redirectOnFail, navigate]);

  useEffect(() => {
    if (isLoggedIn && shouldFetch && !network && !loading) {
      dispatch(getNetworkRequest());
      setShouldFetch(false);
    }
    if (isLoggedIn && shouldRefetch) {
      dispatch(getNetworkRequest());
      setShouldRefetch(false);
    }
  }, [dispatch, shouldFetch, shouldRefetch, network, loading, isLoggedIn]);

  const refetch = () => {
    if (isLoggedIn) {
      setShouldRefetch(true);
    }
  };

  return { network, loading, error, refetch };
};
