/*
Only some ticket releases method can edit payment deadline
For instance, FCFS and FCFS-lottery ticket releases can edit payment deadline
But not Reserved or Selective
*/

import { ITicketReleaseMethod, ITicketReleaseMethodType } from "../../types";

const METHODS_THAT_CAN_EDIT_PAYMENT_DEADLINE: string[] = [
  ITicketReleaseMethodType.FCFS,
  ITicketReleaseMethodType.FCFS_LOTTERY,
];

const METHODS_THAT_HAS_RESERVE_TICKETS: string[] = [
  ITicketReleaseMethodType.FCFS,
  ITicketReleaseMethodType.FCFS_LOTTERY,
];

const METHODS_THAT_CAN_MASS_ALLOCATE_TICKETS: string[] = [
  ITicketReleaseMethodType.FCFS,
  ITicketReleaseMethodType.FCFS_LOTTERY,
];

const METHODS_THAT_HAS_LOTTERY: string[] = [
  ITicketReleaseMethodType.FCFS_LOTTERY,
];

const METHODS_THAT_REQUIRE_ACCOUNT: string[] = [
  ITicketReleaseMethodType.FCFS,
  ITicketReleaseMethodType.FCFS_LOTTERY,
];

export const METHODS_WHERE_USERS_CAN_REQUEST_MORE_THAN_ONE_TICKET: string[] = [
  ITicketReleaseMethodType.RESERVED_TICKET_RELEASE,
  ITicketReleaseMethodType.SELECTIVE,
  ITicketReleaseMethodType.PAY_DIRECT,
];

export const ticketReleaseCanHaveMultipleTickets = (name: string) => {
  return METHODS_WHERE_USERS_CAN_REQUEST_MORE_THAN_ONE_TICKET.includes(name);
};

export const ticketReleaseRequiresAccount = (method?: ITicketReleaseMethod) => {
  if (!method) {
    return false;
  }

  return METHODS_THAT_REQUIRE_ACCOUNT.includes(method.name);
};

export const canEditPaymentDeadline = (method?: ITicketReleaseMethod) => {
  if (!method) {
    return false;
  }

  return METHODS_THAT_CAN_EDIT_PAYMENT_DEADLINE.includes(method.name);
};

export const canEditPaymentDeadlineFromId = (id: number) => {
  return true;
};

export const canEditReservePaymentDurationFromId = (id: number) => {
  return [1, 3].includes(id);
};

export const hasReserveTickets = (method?: ITicketReleaseMethod) => {
  if (!method) {
    throw new Error("Method is required");
  }

  return METHODS_THAT_HAS_RESERVE_TICKETS.includes(method.name);
};

export const canMassAllocateTickets = (method?: ITicketReleaseMethod) => {
  if (!method) {
    throw new Error("Method is required");
  }

  return METHODS_THAT_CAN_MASS_ALLOCATE_TICKETS.includes(method.name);
};

export const hasLottery = (method?: ITicketReleaseMethod) => {
  if (!method) {
    throw new Error("Method is required");
  }

  return METHODS_THAT_HAS_LOTTERY.includes(method.name);
};

export const requiresUpfrontPaymentFromName = (name: string) => {
  return name === ITicketReleaseMethodType.PAY_DIRECT;
};

export const requiresUpfrontPayment = (method?: ITicketReleaseMethod) => {
  // If its pay direct, then it requires upfront payment
  if (!method) {
    return false;
  }

  return requiresUpfrontPaymentFromName(method.name);
};
