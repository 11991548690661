import usePalette from "../../theme/use_palette";

export type PaymentPlanOption = "monthly" | "yearly";

export enum PackageTiers {
  Free = "free",
  Professional = "professional",
  Business = "business",
}

export interface IPricingOption {
  title: string;
  plan: PackageTiers;
  one_time?: {
    SEK: number;
  };
  price?: {
    monthly?: {
      SEK: number | string;
    };
    yearly?: {
      SEK: number | string;
    };
  };
  features: string[];
  highlighted: boolean;
  background?: string;
  enabled?: boolean;
}

const VAT_RATE = 0.25;

const usePricingOptions = () => {
  const palette = usePalette();
  return [
    {
      title: "Free",
      plan: PackageTiers.Free,
      price: {
        monthly: { SEK: 0 },
        yearly: { SEK: 0 },
      },
      features: [
        "ALL features (BETA)",
        // "Max 1 Event",
        // "Basic Ticket Management",
        // "Public Events",
        // "Limited Ticket Releases",
        // "Default Landing Page",
        // "Basic Support",
      ],
      highlighted: true,
      background: `linear-gradient(180deg, ${palette.alwaysBlack} 0%, ${palette.black} 100%)`,
      enabled: true,
    },
    {
      title: "Professional",
      plan: PackageTiers.Professional,
      price: {
        monthly: { SEK: "TBD" }, // Billed monthly
        yearly: { SEK: "TBD" }, // Monthly equivalent paid yearly
      },
      features: [
        "30 events / year",
        "Advanced Ticket Management",
        "Private Events",
        "All Ticket Release Methods",
        "Max 5 Teams",
        "Custom Event Landing Page",
        "Premium Support",
        "...and more",
      ],
      highlighted: false,
      background: `linear-gradient(180deg, ${palette.alwaysBlack} 0%, ${palette.primaryDark} 100%)`,
      enabled: false,
    },
    {
      title: "Business",
      plan: PackageTiers.Business,
      price: {
        monthly: { SEK: 0 },
        yearly: { SEK: 0 },
      },
      features: [
        "Unlimited Events",
        "Unlock all features",
        "Custom Business Event Site",
        "API Integration",
        "Unlimited Teams",
        "Contact Database",
        "Dedicated Support",
        "Tailored Ticket releases",
        "...fully customizable",
      ],
      highlighted: false,
      background: `linear-gradient(180deg, ${palette.alwaysWhite} 0%, ${palette.primaryDark} 100%)`,
      enabled: false,
    },
  ];
};

export default usePricingOptions;
