import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { useEffect } from "react";
import { logoutRequest } from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../Loading";
import { ROUTES } from "../../routes/def";

const Logout: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("promo_codes");
    dispatch(logoutRequest());
    navigate(ROUTES.MAIN);
  });

  return <LoadingOverlay />;
};

export default Logout;
