// src/components/tickets/edit_ticket_details_modal.tsx
import React, { Suspense, useEffect, useState } from "react";
import { Box, Modal, ModalClose, ModalDialog, Stack } from "@mui/joy";
import {
  AttendeeFormValues,
  IGuestCustomer,
  ITicket,
  ITicketOrder,
} from "../../types";
import LoadingComponentSpecific from "../Loading/content_loading";
import StyledText from "../text/styled_text";
import StyledButton from "../buttons/styled_button";
import usePalette from "../../theme/use_palette";
import { useTranslation } from "react-i18next";
import AttendeeDetailsForm from "../events/ticket_release/ticket_request/attendee_details_form";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { updateAttendees } from "../../redux/sagas/axios_calls/ticket/attendee_service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getMyTicketOrdersRequest } from "../../redux/features/myTicketOrderSlice";
import { getMyTicketsRequest } from "../../redux/features/myTicketsSlice";
import { ScrollConfig } from "../constant/scroll_config";
import LoadingOverlay from "../Loading";

const EditFormFieldResponse = React.lazy(
  () => import("../events/form_field_response")
);

interface EditTicketDetailsModalProps {
  open: boolean;
  onClose: () => void;
  ticketOrder: ITicketOrder;
  formFields: any[];
  onRefetch?: () => void;
  guestCustomer?: IGuestCustomer;
}

const EditTicketDetailsModal: React.FC<EditTicketDetailsModalProps> = ({
  open,
  onClose,
  ticketOrder,
  formFields,
  onRefetch,
  guestCustomer,
}) => {
  const palette = usePalette();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const [isRefetching, setIsRefetching] = useState(false);

  const event_id = ticketOrder.ticket_release?.event_id!;

  const showFormFields =
    formFields.length > 0 &&
    !ticketOrder.deleted_at &&
    ((!ticketOrder.is_paid && !ticketOrder.is_refunded) ||
      (ticketOrder.is_paid &&
        ticketOrder.ticket_release?.ticket_release_method_detail
          .ticket_release_method?.name === "pay_direct"));

  const handleAttendeesSubmit = async (
    values: AttendeeFormValues,
    ticket_order_id: number,
    terms_agreed: boolean
  ) => {
    setIsRefetching(true);

    try {
      const response = await updateAttendees(
        event_id,
        {
          tickets: ticketOrder.tickets!.map((ticket: ITicket, index) => ({
            ticket_id: ticket.id,
            attendee: values.attendees[index],
          })),
          ticket_order_id,
          terms_agreed,
        },
        guestCustomer
      );

      if (response.status === "success") {
        toast.success("Attendees updated successfully");
        onRefetch && onRefetch();
      }
    } catch (error) {
      toast.error("Failed to update attendees");
    } finally {
      setIsRefetching(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        sx={{
          maxWidth: 800,
          width: "90%",
          maxHeight: "90vh",
          overflowY: "auto",
          backgroundColor: palette.white,
        }}
      >
        <ModalClose />
        <Box
          sx={{
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          {isRefetching && <LoadingOverlay />}
          <AttendeeDetailsForm
            ticketOrder={ticketOrder}
            currentUser={currentUser}
            onSubmit={handleAttendeesSubmit}
            btnText={t("tickets.attendee_details.buttons.save_attendees")}
            isEdit={true}
            formFields={formFields}
            eventID={ticketOrder.ticket_release?.event_id!}
            showFormFields={showFormFields}
            refetch={onRefetch}
          />
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default EditTicketDetailsModal;
