import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEvent, INetwork } from "../../types";

interface NetworkBusinessPageState {
  network: INetwork | null;
  events: IEvent[];
  loading: boolean;
  error: {
    status: number | null;
    message: string | null;
  } | null;
}

const initialState: NetworkBusinessPageState = {
  network: null,
  events: [],
  loading: false,
  error: null,
};

const networkBusinessPageSlice = createSlice({
  name: "networkBusinessPage",
  initialState,
  reducers: {
    getNetworkBusinessPageRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = null;
    },
    getNetworkBusinessPageSuccess: (
      state,
      action: PayloadAction<{ network: INetwork; events: IEvent[] }>
    ) => {
      state.loading = false;
      state.network = action.payload.network;
      state.events = action.payload.events;
    },
    getNetworkBusinessPageFailure: (
      state,
      action: PayloadAction<{
        status: number | null;
        message: string | null;
      }>
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getNetworkBusinessPageRequest,
  getNetworkBusinessPageSuccess,
  getNetworkBusinessPageFailure,
} = networkBusinessPageSlice.actions;

export default networkBusinessPageSlice.reducer;
