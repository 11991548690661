import { Box, Link, Stack } from "@mui/joy";
import { IEvent, ITicketRelease } from "../../../types";
import StyledText from "../../../components/text/styled_text";
import usePalette from "../../../theme/use_palette";
import TicketRelease from "../../../components/events/ticket_release";
import { Trans, useTranslation } from "react-i18next";
import StyledButton from "../../../components/buttons/styled_button";
import { useEffect } from "react";

interface ShowEventsTicketReleaseProps {
  ticketReleases: ITicketRelease[];
  event: IEvent;
}

const ShowEventsTicketReleases: React.FC<ShowEventsTicketReleaseProps> = ({
  ticketReleases,
  event,
}) => {
  const { t } = useTranslation();
  const palette = usePalette();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Handle intersection logic here
          }
        });
      },
      {
        root: null,
        threshold: 0.2,
      }
    );

    ticketReleases.forEach((_, index) => {
      const element = document.getElementById(`ticket-release-${index}`);
      if (element) observer.observe(element);
    });

    // Cleanup
    return () => {
      ticketReleases.forEach((_, index) => {
        const element = document.getElementById(`ticket-release-${index}`);
        if (element) observer.unobserve(element);
      });
    };
  }, [ticketReleases]);

  return (
    <Box>
      {ticketReleases.length === 0 && (
        <StyledText
          color={palette.charcoalTransparent}
          level="body-sm"
          fontSize={22}
          fontWeight={500}
          style={{
            marginTop: "1rem",
          }}
        >
          {t("event.no_ticket_releases")}
        </StyledText>
      )}
      <Stack
        spacing={2}
        sx={{
          p: 0,
        }}
      >
        {ticketReleases.map((ticketRelease, i) => {
          const key = `${event.name}-${i}`;
          const ticketReleaseId = `ticket-release-${i}`;

          return (
            <Box key={key} id={ticketReleaseId}>
              <TicketRelease ticketRelease={ticketRelease} />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default ShowEventsTicketReleases;
