import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "./theme/theme_context";
import ReactGA from "react-ga4";

ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID!,
    gtagOptions: {
      debug_mode: process.env.NODE_ENV === "development",
    },
  },
]);

function App() {
  if (!process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID) {
    console.error("REACT_APP_GOOGLE_OAUTH_CLIENT_ID is not set");
    throw new Error("REACT_APP_GOOGLE_OAUTH_CLIENT_ID is not set");
  }

  // Initialize Google Analytics
  useEffect(() => {
    // Optional: You can send a pageview for the initial page load
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []); // Empty dependency array ensures it runs once on mount

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!}
        >
          <ThemeProvider>
            <AppRoutes />
          </ThemeProvider>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
