import { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getGuestCustomerFailure,
  getGuestCustomerRequest,
  getGuestCustomerSuccess,
} from "../features/guestCustomerSlice";

import { toast } from "react-toastify";
import {
  IAddon,
  IEvent,
  IEventFormField,
  IEventFormFieldResponse,
  IGuestCustomer,
  ITicket,
  ITicketAddon,
  ITicketRelease,
  ITicketType,
} from "../../types";
import { ApiResponse, fetchApi } from "../../utils/api/api";

function* getGuestSaga(
  action: PayloadAction<{
    ugkthid: string;
    request_token: string;
  }>
): Generator<any, void, any> {
  try {
    const { ugkthid, request_token } = action.payload;

    const url = `/guest-customer/${ugkthid}?request_token=${request_token}`;

    const response: ApiResponse<{
      user: IGuestCustomer;
    }> = yield call(fetchApi, url, true, false);

    const user = response.data.user;

    if (user.ticket_orders && user.ticket_orders.length > 1) {
      toast.error("An unexpected error occurred, contact support");
      yield put(
        getGuestCustomerFailure("An unexpected error occurred, contact support")
      );
      return;
    }

    const ticket_orders = user.ticket_orders;

    const guest: IGuestCustomer = {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone_number: user.phone_number,
      request_token: request_token,
      roles: user.roles,
      verified_email: user.verified_email,
      ticket_orders: ticket_orders,
    };

    yield put(getGuestCustomerSuccess(guest));
  } catch (error: any) {
    const errorMessage = error.response.data.error || "An error occurred";
    toast.error(errorMessage);
    yield put(getGuestCustomerFailure(error));
  }
}

export function* watchGuestSaga() {
  yield takeLatest(getGuestCustomerRequest.type, getGuestSaga);
}
