import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ITicketRelease, ISelectedAddon } from "../../../../types";
import { RootState } from "../../../../store";
import { Trans, useTranslation } from "react-i18next";
import usePalette, { useIsDarkTheme } from "../../../../theme/use_palette";
import { closeCartModal } from "../../../../redux/features/ticketOrderSlice";
import InformationModal from "../../../modal/information";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Link,
  Stack,
  useTheme,
} from "@mui/joy";
import StyledText from "../../../text/styled_text";
import { LEGAL_LINKS } from "../../../legal/legal_links";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import StyledButton from "../../../buttons/styled_button";
import { requiresUpfrontPayment } from "../../../../utils/manage_event/can_edit_payment_deadline";
import { useMediaQuery } from "@mui/material";

interface CartSummaryModalProps {
  ticketRelease: ITicketRelease;
  onProceed: (
    ticketsData: {
      ticket_type_id: number;
      addons: ISelectedAddon[];
    }[]
  ) => void;
}

const CartSummaryModal: React.FC<CartSummaryModalProps> = ({
  ticketRelease,
  onProceed,
}) => {
  const dispatch = useDispatch();
  const { isCartModalOpen, items } = useSelector(
    (state: RootState) => state.ticketOrder
  );
  const { t } = useTranslation();
  const palette = usePalette();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const darkMode = useIsDarkTheme();

  // Filter items for the current ticket release
  const cartItemsForRelease = items.filter(
    (item) => item.ticket.ticket_release_id === ticketRelease.id
  );

  const handleClose = () => {
    dispatch(closeCartModal());
  };

  // Calculate total amount including addons
  const totalAmount = cartItemsForRelease.reduce((total, item) => {
    const ticketPrice = item.ticket.price;
    const addonsPrice = item.addons.reduce((sum, addon) => {
      const addonInfo = ticketRelease.add_ons?.find((a) => a.id === addon.id);
      return sum + (addonInfo ? addonInfo.price * addon.quantity : 0);
    }, 0);
    return total + ticketPrice + addonsPrice;
  }, 0);

  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleProceed = () => {
    if (!privacyAccepted || !termsAccepted) {
      return;
    }
    // Prepare tickets data for submission
    const ticketsData = cartItemsForRelease.map((item) => ({
      ticket_type_id: item.ticket.id,
      addons: item.addons,
    }));

    // Close the cart modal
    dispatch(closeCartModal());

    // Open the MakeTicketOrderWorkflow modal via the passed prop
    onProceed(ticketsData);
  };

  const ticketGroups = cartItemsForRelease.reduce((acc, item) => {
    const typeName = item.ticket.name;
    if (!acc[typeName]) {
      acc[typeName] = {
        count: 0,
        price: item.ticket.price,
        total: 0,
        items: [],
      };
    }
    acc[typeName].count += 1;
    acc[typeName].total += item.ticket.price;
    acc[typeName].items.push(item);
    return acc;
  }, {} as Record<string, { count: number; price: number; total: number; items: typeof cartItemsForRelease }>);

  return (
    <InformationModal
      isOpen={isCartModalOpen}
      onClose={handleClose}
      title={t("event.ticket_release.checkout.cart_summary")}
      width="750px"
    >
      <Box>
        <StyledText
          level="body-md"
          color={palette.charcoal}
          sx={{
            mb: 2,
          }}
        >
          {t("event.ticket_release.checkout.cart_description")}
        </StyledText>
        {/* Display cart items and total */}
        {Object.entries(ticketGroups).map(([typeName, group]) => (
          <Box key={typeName} mb={2}>
            <Box display="flex" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                <ConfirmationNumberIcon />
                <StyledText level="body-md" color={palette.charcoal}>
                  {group.count}x {typeName}
                </StyledText>
              </Stack>
              <StyledText level="body-sm" color={palette.charcoal}>
                {group.total.toFixed(2)} kr
              </StyledText>
            </Box>

            {/* Display addons for all tickets of this type */}
            {group.items.map(
              (item, itemIndex) =>
                item.addons.length > 0 && (
                  <Box key={itemIndex} ml={2} mt={1}>
                    {item.addons.map((addon) => {
                      const addonInfo = ticketRelease.add_ons?.find(
                        (a) => a.id === addon.id
                      );
                      return (
                        <Box
                          key={addon.id}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Stack direction="row" spacing={1}>
                            <AddCircleOutlineIcon />
                            <StyledText
                              level="body-sm"
                              color={palette.charcoal}
                            >
                              {addon.quantity}x {addonInfo?.name}
                            </StyledText>
                          </Stack>
                          <StyledText level="body-sm" color={palette.charcoal}>
                            {((addonInfo?.price || 0) * addon.quantity).toFixed(
                              2
                            )}{" "}
                            kr
                          </StyledText>
                        </Box>
                      );
                    })}
                  </Box>
                )
            )}
          </Box>
        ))}
        <Divider sx={{ mt: 0.5, mb: 1 }} />
        <StyledText
          level="body-md"
          fontWeight={700}
          color={palette.charcoal}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {t("Total")}: {totalAmount.toFixed(2)} kr
        </StyledText>

        {/* Display relevant texts */}
        <Box mt={3} display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="flex-start" gap={1}>
            <Checkbox
              checked={privacyAccepted}
              onChange={(e) => setPrivacyAccepted(e.target.checked)}
              size="md"
              sx={{ mt: 0.5 }}
            />
            <StyledText
              level="body-sm"
              fontWeight={400}
              fontSize={14}
              color={palette.black}
            >
              {t("event.ticket_release.legal.privacy_consent")}{" "}
              <Link href={LEGAL_LINKS.privacy_policy} target="_blank">
                {t("legal.privacy_policy")}
              </Link>
            </StyledText>
          </Box>

          <Box display="flex" alignItems="flex-start" gap={1}>
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              size="md"
              sx={{ mt: 0.5 }}
            />
            <StyledText
              level="body-sm"
              fontWeight={400}
              fontSize={14}
              color={palette.black}
            >
              {t("event.ticket_release.legal.terms_consent")}{" "}
              <Link href={LEGAL_LINKS.terms_and_conditions} target="_blank">
                {t("legal.terms")}
              </Link>
              {" & "}
              <Link href={LEGAL_LINKS.refund_policy} target="_blank">
                {t("legal.return")}
              </Link>
            </StyledText>
          </Box>
        </Box>

        {/* Proceed Button */}
        <Stack
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
          sx={{
            height: "fit-content",
            mt: 4,
          }}
        >
          <StyledButton
            size="md"
            onClick={handleProceed}
            sx={{ maxWidth: "300px" }}
            color={palette.white}
            bgColor={palette.black}
            disabled={!privacyAccepted || !termsAccepted}
          >
            {requiresUpfrontPayment(
              ticketRelease.ticket_release_method_detail.ticket_release_method
            )
              ? t("event.ticket_release.checkout.proceed_payment")
              : t("event.ticket_release.checkout.proceed_request")}
          </StyledButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <img
              src="/assets/payment-logos/ma.svg"
              alt="Mastercard"
              style={{
                height: "30px",
                width: "auto",
              }}
            />
            <img
              src="/assets/payment-logos/visa.png"
              alt="Visa"
              style={{
                height: "30px",
                width: "auto",
              }}
            />
            <img
              src={
                !darkMode
                  ? "/assets/payment-logos/sw-light.svg"
                  : "/assets/payment-logos/sw-dark.svg"
              }
              alt="Swish"
              style={{
                height: "50px",
                width: "auto",
              }}
            />
          </Box>
        </Stack>
      </Box>
    </InformationModal>
  );
};

export default CartSummaryModal;
