import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  loginSuccess,
  loginFailure,
  logoutRequest,
} from "../features/authSlice";
import { IUser, LoginCredentials } from "../../types";
import {
  currentUserFailure,
  currentUserRequest,
  currentUserSuccess,
  deactivateAccountRequest,
  deactivateAccountSuccess,
  deactivateAccountFailure,
  updateUsersProfileRequest,
  updateUsersProfileSuccess,
  updateUsersProfileFailure,
  IUpdateProfileData,
  resetUser,
} from "../features/userSlice";
import { IsUsingSubdomain } from "../../utils/subdomain/subdomain_utils";
import { toast } from "react-toastify";
import { PayloadAction } from "@reduxjs/toolkit";
import { deleteApi, putApi } from "../../utils/api/api";
import ApiRoutes from "../../routes/backend_routes";

function* updateUserNameSaga(
  action: PayloadAction<IUpdateProfileData>
): Generator<any, void, any> {
  try {
    const response = yield call(
      putApi,
      ApiRoutes.PROFILE_NAME,
      action.payload,
      true,
      true
    );
    const updatedUser: IUser = response.data.user;
    yield put(updateUsersProfileSuccess(updatedUser));
    toast.success("Name updated successfully");
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error ||
      "An error occurred while updating your name";
    yield put(updateUsersProfileFailure(errorMessage));
    toast.error(errorMessage);
  }
}

function* deactivateAccountSaga() {
  try {
    yield call(deleteApi, ApiRoutes.PROFILE_DEACTIVATE, {}, true, true);
    yield put(logoutRequest());
    yield put(deactivateAccountSuccess());
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error ||
      "An error occurred while deactivating your account";
    yield put(deactivateAccountFailure(errorMessage));
    toast.error(errorMessage);
  }
}

function* userSaga(): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_BACKEND_URL}/current-user`,
      {
        withCredentials: true, // This ensures cookies are sent with the request
      }
    );

    const user = response.data.user;

    // Yield both currentUserSuccess and loginSuccess
    yield put(currentUserSuccess(user));
    yield put(loginSuccess({ user: user }));
  } catch (error: any) {
    if (!IsUsingSubdomain()) {
      yield put(loginFailure(error.message));
      yield put(currentUserFailure(error.message));
    }
  }
}

function* watchUserSaga() {
  yield takeLatest(updateUsersProfileRequest.type, updateUserNameSaga);
  yield takeLatest(deactivateAccountRequest.type, deactivateAccountSaga);
  yield takeLatest(currentUserRequest.type, userSaga);
}

export default watchUserSaga;
