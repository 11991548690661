import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import StyledText from "../../components/text/styled_text";
import usePalette from "../../theme/use_palette";
import { ROUTES } from "../../routes/def";
import { Link } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { getCustomerEventRequest } from "../../redux/features/customerViewEvent";
import { resetPostSuccess } from "../../redux/features/ticketOrderSlice";

export const useEventEffects = (
  postSuccess: boolean,
  errorStatusCode: number | null,
  refID: string,
  secretToken: string | null,
  subdomain: string = "",
  error?: string | null
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const palette = usePalette();

  const [displayPostSuccess, setDisplayPostSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (postSuccess) {
      setDisplayPostSuccess(true);
      dispatch(resetPostSuccess());
    }
  }, [dispatch, postSuccess]);

  useEffect(() => {
    if (displayPostSuccess) {
      toast.success(
        <StyledText color={palette.charcoal} level="body-sm" fontWeight={600}>
          Ticket order created! View it <Link href={ROUTES.PROFILE}>here</Link>
        </StyledText>
      );
    }
  }, [displayPostSuccess]);

  useEffect(() => {
    if (errorStatusCode === 404) {
      navigate("/404");
    } else if (errorStatusCode === 403) {
      setTimeout(() => {
        toast.error(error);
      }, 1000);
      navigate("/");
    } else if (errorStatusCode) {
      setTimeout(() => {
        toast.error(error);
      }, 1000);
      navigate("/");
    }
  }, [errorStatusCode, error]);

  useEffect(() => {
    if (!refID && !subdomain) {
      console.error("No event ref found");
      return;
    }
    const promoCodes = JSON.parse(localStorage.getItem("promo_codes") || "[]");
    dispatch(
      getCustomerEventRequest({
        refID,
        secretToken: secretToken || "",
        countSiteVisit: true,
        promoCodes,
        subdomain,
      })
    );
  }, [dispatch, refID, secretToken]);

  return { displayPostSuccess };
};
