// src/components/manager/ai/TypingIndicator.tsx
import React from "react";
import { Box } from "@mui/joy";
import usePalette from "../../../theme/use_palette";
import { keyframes } from "@emotion/react";

const TypingIndicator: React.FC = () => {
  const palette = usePalette();

  const shimmer = keyframes`
    0% {
      background-position: -200% center;
    }
    100% {
      background-position: 200% center;
    }
  `;

  return (
    <Box
      sx={{
        display: "flex",
        p: 2,
        borderRadius: 2,
        maxWidth: "70%",
      }}
    >
      <Box
        sx={{
          fontSize: "1rem",
          fontWeight: 600,
          background: `linear-gradient(
            90deg, 
            ${palette.black} 0%, 
            ${palette.greyLight} 20%,
            ${palette.black} 40%,
            ${palette.greyLight} 60%,
            ${palette.black} 80%,
            ${palette.greyLight} 100%
          )`,
          backgroundSize: "200% auto",
          color: "transparent",
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          animation: `${shimmer} 2s linear infinite`,
        }}
      >
        Thinking...
      </Box>
    </Box>
  );
};

export default TypingIndicator;
