// src/components/events/ticket_request/attendee_details_form.tsx

import React, { useEffect } from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  Grid,
  Link,
  Option,
  Select,
  SelectOption,
  Stack,
  Textarea,
} from "@mui/joy";
import {
  AttendeeFormValues,
  FoodPreferences,
  IAttendeeFoodPreference,
  IEventFormField,
  IFoodPreference,
  ITicket,
  ITicketOrder,
  IUserFoodPreference,
} from "../../../../types";
import {
  StyledFormLabel,
  StyledFormLabelWithHelperText,
} from "../../../forms/form_labels";
import { FormInput } from "../../../forms/input_types";
import { StyledErrorMessage } from "../../../forms/messages";
import StyledText from "../../../text/styled_text";
import usePalette from "../../../../theme/use_palette";
import Title from "../../../text/title";
import StyledButton from "../../../buttons/styled_button";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { fetchUserFoodPreferencesStart } from "../../../../redux/features/userFoodPreferences";
import { useDispatch } from "react-redux";
import { ROUTES } from "../../../../routes/def";
import { darkerColor } from "../../../../utils/manager/color";
import { LEGAL_LINKS } from "../../../legal/legal_links";
import zIndex from "@mui/material/styles/zIndex";
import EditFormFieldResponse from "../../../events/form_field_response";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// Define the food preference options

const convertPreferencesToArray = (preferences: any): string[] => {
  return Object.entries(preferences)
    .filter(([key, value]) => value === true && key !== "additional_info")
    .map(([key]) => key);
};

const convertArrayToPreferences = (preferences: string[]): any => {
  const result: any = {
    gluten_intolerant: false,
    lactose_intolerant: false,
    vegetarian: false,
    vegan: false,
    nut_allergy: false,
    shellfish_allergy: false,
    egg_allergy: false,
    soy_allergy: false,
    halal: false,
    kosher: false,
  };
  preferences.forEach((pref) => {
    result[pref] = true;
  });
  return result;
};

const validationSchema = Yup.object().shape({
  attendees: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone_number: Yup.string(),
      food_preferences: Yup.object().shape({
        preferences: Yup.array().of(Yup.string()),
        additional_info: Yup.string(),
      }),
    })
  ),
});

interface AttendeeDetailsFormProps {
  ticketOrder: ITicketOrder;
  currentUser: {
    first_name: string;
    last_name: string;
    email: string;
    phone_number?: string;
  };
  onSubmit: (
    values: AttendeeFormValues,
    ticket_order_id: number,
    terms_agreed: boolean
  ) => void;
  btnText?: string;
  isEdit?: boolean;
  formFields?: IEventFormField[];
  eventID: number;
  showFormFields?: boolean;
  refetch?: () => void;
}

const AttendeeDetailsForm: React.FC<AttendeeDetailsFormProps> = ({
  ticketOrder,
  currentUser,
  onSubmit,
  isEdit = false,
  btnText = "Continue",
  formFields = [],
  eventID,
  showFormFields = true,
  refetch,
}) => {
  const palette = usePalette();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const [privacyPolicyAgree, setPrivacyPolicyAgree] = React.useState(
    ticketOrder.attendee_details_gdpr
  );
  const [shareAgree, setShareAgree] = React.useState(
    ticketOrder.attendee_details_gdpr
  );
  const {
    userFoodPreferences,
    additionalNotes,
    loading: loadingFoodPref,
  } = useSelector((state: RootState) => state.foodPreferences);

  const { guestCustomer, loading } = useSelector(
    (state: RootState) => state.guestCustomer
  );

  useEffect(() => {
    if (!loading) {
      dispatch(fetchUserFoodPreferencesStart({ guestCustomer }));
    }
  }, [dispatch, guestCustomer, loading]);

  const initialValues: AttendeeFormValues = {
    attendees: ticketOrder.tickets.map((ticket, index) => {
      if (isEdit && ticket.ticket_attendee) {
        const preferences = convertPreferencesToArray(
          ticket.ticket_attendee.food_preferences
        );

        return {
          first_name: ticket.ticket_attendee.first_name,
          last_name: ticket.ticket_attendee.last_name,
          email: ticket.ticket_attendee.email,
          phone_number: ticket.ticket_attendee.phone_number || "",
          food_preferences: {
            preferences,
            additional_info:
              ticket.ticket_attendee.food_preferences.additional_info || "",
          },
        };
      }

      return index === 0
        ? {
            first_name: currentUser.first_name,
            last_name: currentUser.last_name,
            email: currentUser.email,
            phone_number: currentUser.phone_number || "",
            food_preferences: {
              preferences: userFoodPreferences
                .filter((pref: IFoodPreference) => pref.checked)
                .map((pref: IFoodPreference) => pref.id),
              additional_info: additionalNotes,
            },
          }
        : {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            food_preferences: {
              preferences: [],
              additional_info: "",
            },
          };
    }),
  };

  const handleSubmit = (values: AttendeeFormValues) => {
    // Convert array preferences back to checkbox style before submitting
    const convertedValues = {
      attendees: values.attendees.map((attendee) => ({
        ...attendee,
        food_preferences: {
          ...convertArrayToPreferences(attendee.food_preferences.preferences),
          additional_info: attendee.food_preferences.additional_info,
        },
      })),
    };
    onSubmit(convertedValues, ticketOrder.id, privacyPolicyAgree && shareAgree);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount={true}
    >
      {({ values, getFieldProps, setFieldValue }) => (
        <Form>
          <Box
            sx={{
              mb: 2,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: palette.offWhite,
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: palette.greyLight,
                borderRadius: "4px",
                "&:hover": {
                  background: palette.greyLight,
                },
              },
            }}
          >
            <Title fontSize={28}>{t("tickets.attendee_details.title")}</Title>
            <StyledText
              level="body-md"
              color={palette.textSecondary}
              sx={{
                mb: 1,
              }}
              fontSize={16}
            >
              {t("tickets.attendee_details.description")}
            </StyledText>

            <FieldArray name="attendees">
              {() => (
                <Stack spacing={1}>
                  {values.attendees.map((attendee, index) => {
                    const isCurrentUser = index === 0;
                    const currentTicket = ticketOrder.tickets[index];

                    return (
                      <React.Fragment key={index}>
                        <Card
                          variant="outlined"
                          sx={{
                            p: 0,
                            pt: 1,
                            pb: 1.5,
                            px: 2,
                            borderColor: palette.greyLight,
                            backgroundColor:
                              index === 0
                                ? palette.offWhite
                                : darkerColor(palette.white, 0.85),
                          }}
                        >
                          <StyledText
                            level="body-md"
                            color={palette.primaryMain}
                            fontWeight={600}
                          >
                            {index === 0
                              ? t("tickets.attendee_details.primary_attendee")
                              : t("tickets.attendee_details.attendee_number", {
                                  number: index + 1,
                                })}
                          </StyledText>

                          <Grid container spacing={0.5}>
                            {/* Existing Fields */}
                            <Grid xs={12} sm={6}>
                              <FormInput
                                label={t(
                                  "tickets.attendee_details.fields.first_name"
                                )}
                                name={`attendees[${index}].first_name`}
                                placeholder={t(
                                  "tickets.attendee_details.fields.first_name"
                                )}
                                disabled={index === 0}
                              />
                              <StyledErrorMessage
                                fontSize={14}
                                name={`attendees[${index}].first_name`}
                              />
                            </Grid>

                            <Grid xs={12} sm={6}>
                              <FormInput
                                label={t(
                                  "tickets.attendee_details.fields.last_name"
                                )}
                                name={`attendees[${index}].last_name`}
                                placeholder={t(
                                  "tickets.attendee_details.fields.last_name"
                                )}
                                disabled={index === 0}
                              />
                              <StyledErrorMessage
                                fontSize={14}
                                name={`attendees[${index}].last_name`}
                              />
                            </Grid>

                            <Grid xs={12} sm={6}>
                              <FormInput
                                label={t(
                                  "tickets.attendee_details.fields.email"
                                )}
                                name={`attendees[${index}].email`}
                                placeholder={t(
                                  "tickets.attendee_details.fields.email"
                                )}
                                disabled={index === 0}
                              />
                              <StyledErrorMessage
                                fontSize={14}
                                name={`attendees[${index}].email`}
                              />
                            </Grid>

                            <Grid xs={12} sm={6}>
                              <FormInput
                                label={t(
                                  "tickets.attendee_details.fields.phone.label"
                                )}
                                name={`attendees[${index}].phone_number`}
                                placeholder={t(
                                  "tickets.attendee_details.fields.phone.label"
                                )}
                                disabled={index === 0}
                                startDecorator={
                                  <StyledText
                                    level="body-sm"
                                    color={palette.greyDark}
                                    sx={{
                                      mr: 1,
                                    }}
                                    fontSize={14}
                                  >
                                    +46
                                  </StyledText>
                                }
                              />
                              <StyledFormLabelWithHelperText fontSize={12}>
                                {t(
                                  "tickets.attendee_details.fields.phone.optional"
                                )}
                              </StyledFormLabelWithHelperText>
                              <StyledErrorMessage
                                fontSize={14}
                                name={`attendees[${index}].phone_number`}
                              />
                            </Grid>

                            {/* Food Preferences Section */}

                            <Grid xs={12}>
                              <StyledFormLabel>
                                {t(
                                  "tickets.attendee_details.food_preferences.title"
                                )}
                              </StyledFormLabel>
                            </Grid>
                            {isCurrentUser && !guestCustomer ? (
                              <StyledText
                                level="body-sm"
                                color={palette.textSecondary}
                                sx={{
                                  mb: 1,
                                }}
                                fontSize={14}
                                endDecorator={<OpenInNewIcon />}
                              >
                                <Link
                                  href={ROUTES.PROFILE + "?tab=profile"}
                                  target="_blank"
                                >
                                  {t(
                                    "tickets.attendee_details.food_preferences.edit_in_profile"
                                  )}
                                </Link>
                              </StyledText>
                            ) : (
                              <>
                                <FormControl>
                                  <Select
                                    multiple
                                    value={
                                      attendee.food_preferences.preferences
                                    }
                                    disabled={index === 0 && !guestCustomer}
                                    placeholder={t(
                                      "profile.food_preferences.select_placeholder"
                                    )}
                                    onChange={(_, newValue) => {
                                      setFieldValue(
                                        `attendees[${index}].food_preferences.preferences`,
                                        newValue
                                      );
                                    }}
                                    sx={{
                                      width: "300px",
                                    }}
                                    renderValue={(
                                      selected: SelectOption<string>[]
                                    ) => (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: "0.25rem",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {selected.map((value) => {
                                          const option = FoodPreferences.find(
                                            (pref) => pref.id === value.value
                                          );
                                          return option ? (
                                            <Chip
                                              key={option.id}
                                              variant="soft"
                                              color="primary"
                                            >
                                              {option.label}
                                            </Chip>
                                          ) : null;
                                        })}
                                      </Box>
                                    )}
                                    slotProps={{
                                      listbox: {
                                        sx: {
                                          width: "300px",
                                          maxHeight: "300px",
                                          overflow: "auto",
                                          zIndex: 9999,
                                        },
                                      },
                                    }}
                                  >
                                    {FoodPreferences.map((option) => (
                                      <Option key={option.id} value={option.id}>
                                        {option.label}
                                      </Option>
                                    ))}
                                  </Select>
                                </FormControl>
                                <Grid xs={12}>
                                  <StyledFormLabel>
                                    {t(
                                      "tickets.attendee_details.food_preferences.additional_info"
                                    )}
                                  </StyledFormLabel>
                                  <Field
                                    as={Textarea}
                                    disabled={index === 0 && !guestCustomer}
                                    name={`attendees[${index}].food_preferences.additional_info`}
                                    placeholder={t(
                                      "tickets.attendee_details.food_preferences.additional_info_placeholder"
                                    )}
                                    minRows={2}
                                  />
                                </Grid>
                              </>
                            )}
                            {formFields && formFields.length > 0 && eventID && (
                              <Grid xs={12}>
                                <Box mt={2}>
                                  <StyledText
                                    level="body-md"
                                    color={palette.black}
                                    fontWeight={600}
                                  >
                                    {t("tickets.attendee_details.form_fields")}
                                  </StyledText>
                                  <EditFormFieldResponse
                                    tickets={[currentTicket]}
                                    eventID={eventID}
                                    formFields={formFields}
                                    isGuestCustomer={
                                      !!guestCustomer ? true : false
                                    }
                                    hideSubmitButton={false}
                                    refetch={refetch}
                                  />
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Card>
                      </React.Fragment>
                    );
                  })}
                </Stack>
              )}
            </FieldArray>
          </Box>

          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              pt: 2,
              borderRadius: "8px 8px 0 0",
              boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.2)",
              backgroundColor: palette.white,
              zIndex: 1200,
            }}
          >
            {!ticketOrder.attendee_details_gdpr && (
              <Box>
                <FormControl>
                  <Stack direction="row" spacing={1} alignItems="flex-start">
                    <Checkbox
                      checked={privacyPolicyAgree}
                      onChange={(e) => setPrivacyPolicyAgree(e.target.checked)}
                      sx={{ mt: 0.5 }} // Align with first line of text
                    />
                    <StyledFormLabelWithHelperText>
                      <Trans i18nKey="tickets.attendee_details.food_preferences.agree_text_1">
                        I agree to the processing of my personal data for the
                        purpose of managing my food preferences
                        <Link href={LEGAL_LINKS.privacy_policy} target="_blank">
                          Privacy Policy
                        </Link>
                      </Trans>
                    </StyledFormLabelWithHelperText>
                  </Stack>
                </FormControl>

                <FormControl sx={{ mt: 1 }}>
                  <Stack direction="row" spacing={1} alignItems="flex-start">
                    <Checkbox
                      checked={shareAgree}
                      onChange={(e) => setShareAgree(e.target.checked)}
                      sx={{ mt: 0.5 }}
                    />
                    <StyledFormLabelWithHelperText>
                      <Trans i18nKey="tickets.attendee_details.food_preferences.agree_text_2" />
                    </StyledFormLabelWithHelperText>
                  </Stack>
                </FormControl>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                zIndex: 1,
                mt: 2,
              }}
            >
              <StyledButton
                type="submit"
                size="md"
                bgColor={palette.black}
                color={palette.white}
                disabled={
                  !privacyPolicyAgree ||
                  !shareAgree ||
                  values.attendees.some(
                    (attendee) =>
                      !attendee.first_name ||
                      !attendee.last_name ||
                      !attendee.email
                  )
                }
              >
                {btnText}
              </StyledButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AttendeeDetailsForm;
