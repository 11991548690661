import { Typography } from "@mui/joy";
import usePalette from "../../theme/use_palette";
import React from "react";
import StyledText from "./styled_text";

interface TitleProps {
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Title = React.forwardRef<HTMLDivElement, TitleProps>(
  (
    { color, fontSize = 38, fontWeight = 700, children, style, ...props },
    ref
  ) => {
    const palette = usePalette();
    return (
      <StyledText
        level="h1"
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color || palette.primaryDark}
        style={{
          ...style,
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        ref={ref}
        {...props}
      >
        {children}
      </StyledText>
    );
  }
);

export default Title;
