import { call, put, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import { IEvent, INetwork, INetworkRole } from "../../../types";

import { ApiResponse, fetchApi } from "../../../utils/api/api";
import {
  getNetworkRolesFailure,
  getNetworkRolesRequest,
  getNetworkRolesSuccess,
} from "../../features/manager/networkRoleSlice";

function* getNetworkRolesSaga(): Generator<any, void, any> {
  try {
    const response: ApiResponse<{
      roles: INetworkRole[];
    }> = yield call(fetchApi, "/manager/network/roles", true, false);

    yield put(getNetworkRolesSuccess(response.data.roles));
  } catch (error: any) {
    const errorMessage =
      error.response.data.error || error.message || "An error occurred";
    yield put(getNetworkRolesFailure(errorMessage));
  }
}

function* watchNetworkRolesSaga() {
  yield takeLatest(getNetworkRolesRequest.type, getNetworkRolesSaga);
}

export default watchNetworkRolesSaga;
