import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventState, IEvent, IEventDomainSettingsPostReq } from "../../types";

const initialState: { event: IEvent | null; loading: boolean } = {
  event: null,
  loading: false,
};

const eventDomainSlice = createSlice({
  name: "eventDomain",
  initialState,
  reducers: {
    updateEventDomainRequest: (
      state,
      action: PayloadAction<{
        eventID: number;
        domainSettings: IEventDomainSettingsPostReq;
      }>
    ) => {
      state.loading = true;
    },
    updateEventDomainSuccess: (state, action: PayloadAction<null>) => {
      state.loading = false;
      state.event = action.payload;
    },
    updateEventDomainFailure: (
      state,
      action: PayloadAction<{
        error: string;
      }>
    ) => {
      state.loading = false;
    },
  },
});

export const {
  updateEventDomainRequest,
  updateEventDomainSuccess,
  updateEventDomainFailure,
} = eventDomainSlice.actions;

export default eventDomainSlice.reducer;
