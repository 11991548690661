import { useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

const useShowMobileNav = () => {
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:1000px)");

  useEffect(() => {
    if (isMobile) {
      setShowMobileNav(true);
    } else {
      setShowMobileNav(false);
    }
  }, [isMobile]);

  return showMobileNav;
};

export default useShowMobileNav;
