import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import { IEvent } from "../../../types";
import {
  getNetworkEventsFailure,
  getNetworkEventsRequest,
  getNetworkEventsSuccess,
} from "../../features/manager/listNetworkEventsSlice";
import { compareDesc } from "date-fns";
import { ApiResponse, fetchApi } from "../../../utils/api/api";

function* listNetworkEventSaga(): Generator<any, void, any> {
  try {
    const response: ApiResponse<{
      events: IEvent[];
    }> = yield call(fetchApi, "/manager/events", true, false);

    if (response.status == "success") {
      yield put(getNetworkEventsSuccess(response.data.events));
    }
  } catch (error: any) {
    const errorMessage =
      error.responpse.data.error || error.message || "An error occurred";
    yield put(getNetworkEventsFailure(errorMessage));
  }
}

function* watchListNetworkEventSaga() {
  yield takeLatest(getNetworkEventsRequest.type, listNetworkEventSaga);
}

export default watchListNetworkEventSaga;
