import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEvent, INetwork, INetworkRole, ListEventState } from "../../../types";
import { REHYDRATE } from "redux-persist";

interface NetworkState {
  roles: INetworkRole[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: NetworkState = {
  roles: null,
  loading: true,
  error: null,
};

const networkRolesSlice = createSlice({
  name: "managerNetworkRoles",
  initialState,
  reducers: {
    getNetworkRolesRequest: (state) => {
      state.loading = true;
      state.roles = null;
    },
    getNetworkRolesSuccess: (state, action: PayloadAction<INetworkRole[]>) => {
      state.loading = false;
      state.roles = action.payload;
    },
    getNetworkRolesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: {
    // Handle the rehydration action
    [REHYDRATE]: (state, action) => {
      // Customize the rehydrated state as needed
      if (action.payload) {
        return {
          ...state,
          ...action.payload.roles, // Update this slice only
          loading: false,
        };
      }
      return state;
    },
  },
});

export const {
  getNetworkRolesRequest,
  getNetworkRolesSuccess,
  getNetworkRolesFailure,
} = networkRolesSlice.actions;

export default networkRolesSlice.reducer;
