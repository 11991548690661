import { ShoppingCartItem } from "../redux/features/ticketOrderSlice";

// Update to include ticket and addon handling
export const numberOfTicketRequestInBasket = (
  items: ShoppingCartItem[]
): { [key: number]: number } => {
  let counts: { [key: number]: number } = {};

  items.forEach((item) => {
    // If the ticket ID already exists in the counts object, increment its count
    if (counts[item.ticket.id]) {
      counts[item.ticket.id]++;
    } else {
      // If it doesn't exist, initialize its count to 1
      counts[item.ticket.id] = 1;
    }
  });

  return counts;
};

export const numberOfTotalTicketRequestInBasket = (
  items: ShoppingCartItem[],
  ticketReleaseId: number
): number => {
  let total = 0;

  items.forEach((item) => {
    // If the ticket is part of the given release, increment the total count
    if (item.ticket.ticket_release_id === ticketReleaseId) {
      total++;
    }
  });

  return total;
};
