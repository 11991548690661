import React from "react";
import { Outlet, useParams } from "react-router-dom";
import MUITesseraWrapper from "../../components/wrappers/page_wrapper_mui";
import DrawerBoxWrapper from "../../components/wrappers/manager_wrapper";
import AISidebarActivate from "../../components/manager/tessera_ai/siderbar/ai_sidebar_activate";
import { useEventDetails } from "../../hooks/event/use_event_details_hook";

const EventLayout: React.FC = () => {
  const { eventID } = useParams();

  const {
    eventDetail: { event },
  } = useEventDetails(parseInt(eventID!));

  return (
    <MUITesseraWrapper navOptions={{ isDashboard: true }}>
      <DrawerBoxWrapper eventID={eventID}>
        <Outlet /> {/* Renders child routes */}
        <AISidebarActivate
          eventId={parseInt(eventID!)}
          organizationId={event?.organization_id}
        />
      </DrawerBoxWrapper>
    </MUITesseraWrapper>
  );
};

export default EventLayout;
