import { toast } from "react-toastify";
import ApiRoutes from "../routes/backend_routes";
import { ApiResponse, postApi } from "../utils/api/api";
import { IBookDemoValues } from "../types";

export const handleBookDemoRequest = async (data: IBookDemoValues) => {
  const url = ApiRoutes.BOOK_DEMO;


  try {
    const response: ApiResponse<void> = await postApi(url, data, false, true);
    if (response.status === "success") {
      toast.success("Your demo request has been sent successfully");
      return response;
    }
  } catch (error: any) {
    const errorMessage = error.response.data.error || "An error occurred";
    toast.error(errorMessage);
  }
};
