import React from "react";
import { Link } from "react-router-dom";
import { ListItem, ListItemText } from "@mui/material";
import StyledText from "../../text/styled_text";
import usePalette from "../../../theme/use_palette";
import { Chip, Stack } from "@mui/joy";
import RequiredPlanChip from "../../features/required_plan_chip";
import { useSelector } from "react-redux";
import { selectTextColor } from "../../../redux/features/managerThemeSlice";

interface SubButtonProps {
  title: string;
  icon?: JSX.Element;
  clickable: boolean;
  navigateTo: string;
  hasFeatureAccess?: boolean;
  requiredPlan?: string;
  defaultColor: string;
  onClick?: (event: React.MouseEvent) => void;
}

const SubButton: React.FC<SubButtonProps> = ({
  title,
  icon,
  clickable,
  navigateTo,
  hasFeatureAccess = true,
  requiredPlan,
  defaultColor,
  onClick,
}) => {
  const textColor = useSelector(selectTextColor);
  const palette = usePalette();

  return (
    <ListItem
      key={"subbutton-" + title}
      onClick={onClick}
      sx={{
        height: 30,
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      <ListItemText>
        <Stack
          direction="row"
          alignContent={"center"}
          spacing={2}
          justifyContent={"space-between"}
          sx={{
            wrap: "nowrap",
          }}
        >
          <Link
            to={clickable && hasFeatureAccess ? navigateTo : "#"}
            style={{
              textDecoration: "none",
            }}
          >
            <StyledText
              level="body-md"
              fontSize={17}
              color={
                clickable && hasFeatureAccess ? textColor : palette.greyDark
              }
              forceTextPointer={true}
              sx={{
                pl: 0.6,
                cursor: clickable && hasFeatureAccess ? "pointer" : "default",
                "&:hover": {
                  color:
                    clickable && hasFeatureAccess
                      ? textColor
                      : palette.charcoalTransparent,
                  textDecoration: clickable ? "underline" : "none",
                },
              }}
            >
              {title}
            </StyledText>
          </Link>
          {!hasFeatureAccess && (
            <RequiredPlanChip requiredPlan={requiredPlan || ""} />
          )}
        </Stack>
      </ListItemText>
    </ListItem>
  );
};

export default SubButton;
