// src/components/events/landing_page/blocks/dynamic_content.ts

import { Editor } from "grapesjs";
import { IEvent } from "../../../../types";
import { CustomEditor } from "../custom_editor";
import { marked } from "marked";
import {
  formatDate,
  formatEventDate,
} from "../../../../utils/date_conversions";
import { format } from "date-fns";

interface DynamicFields {
  description: string;
  name: string;
  location: string;
  date: string;
  end_date: string;
  organization_name: string;
  organization_description: string;
  organization_icon_text: string;
}

export const constructDynamicFields = (event: IEvent): DynamicFields => {
  return {
    description: event.description || "Loading...",
    name: event.name || "Loading...",
    location: event.location || "Location TBD",
    date: event.date ? format(event.date, "HH:mm, MMMM dd, yyyy") : "Date TBD",
    end_date: event.end_date
      ? format(event.end_date, "HH:mm, MMMM dd, yyyy")
      : "",
    organization_name: event.organization?.name || "Loading...",
    organization_description:
      event.organization?.details?.description || "Description coming soon",
    organization_icon_text: (event.organization?.name || "O")
      .charAt(0)
      .toUpperCase(),
  };
};

export const addDynamicContentComponent = (editor: CustomEditor) => {
  editor.DomComponents.addType("dynamic-content", {
    model: {
      defaults: {
        tagName: "div",
        classes: ["dynamic-content"], // Add default class
        editable: false,
        droppable: false,
        stylable: true,
        attributes: {
          "data-gjs-type": "dynamic-content",
          "data-field": "", // This will be set when the component is used
        },
        traits: [
          {
            type: "select",
            label: "Field",
            name: "data-field",
            options: [
              { id: "name", value: "name", name: "Event Name" },
              {
                id: "description",
                value: "description",
                name: "Event Description",
              },
              { id: "date", value: "date", name: "Event Date" },
              { id: "end_date", value: "end_date", name: "Event End Date" },
              { id: "location", value: "location", name: "Event Location" },
              {
                id: "organization_icon_text",
                value: "organization_icon_text",
                name: "Organization Icon Text",
              },
              {
                id: "organization_name",
                value: "organization_name",
                name: "Organization Name",
              },
              {
                id: "organization_description",
                value: "organization_description",
                name: "Organization Description",
              },
              // Add more fields as needed
            ],
          },
        ],
      },
    },
    view: {
      onRender() {
        const field = this.model.getAttributes()["data-field"];
        if (field && editor && editor.EventData) {
          renderDynamicContent(field, editor.EventData as IEvent, this.el);
        }
      },
    },
  });
};

export const renderDynamicContent = (
  field: keyof DynamicFields,
  eventData: IEvent,
  element: HTMLElement
) => {
  const fields = constructDynamicFields(eventData);
  if (field === "description") {
    const parsedContent = marked.parse(fields[field]);
    if (parsedContent instanceof Promise) {
      parsedContent.then((content) => {
        element.innerHTML = content;
      });
    } else {
      element.innerHTML = parsedContent;
    }
  } else {
    element.innerHTML = fields[field];
  }
};
