import { Box, Card, Divider, Grid } from "@mui/joy";
import usePalette from "../../../theme/use_palette";
import StyledText from "../../text/styled_text";
import {
  IAIEventData,
  IEventPostReq,
  ITicketReleaseMethodType,
  ITicketReleasePostReq,
} from "../../../types";
import { useTranslation } from "react-i18next";

export const mapTicketReleaseMethod = (id: number) => {
  switch (id) {
    case 1:
      return ITicketReleaseMethodType.FCFS_LOTTERY;
    case 2:
      return ITicketReleaseMethodType.RESERVED_TICKET_RELEASE;
    case 3:
      return ITicketReleaseMethodType.FCFS;
    case 4:
      return ITicketReleaseMethodType.SELECTIVE;
    case 5:
      return ITicketReleaseMethodType.PAY_DIRECT;
    default:
      return "Unknown";
  }
};

// Create reusable field component
const Field: React.FC<{
  label: string;
  value: string | number | boolean | Date;
  sx?: any;
}> = ({ label, value, sx = {} }) => {
  const palette = usePalette();

  return (
    <Box sx={{ mb: 1, ...sx }}>
      <StyledText level="body-md" color={palette.charcoal} fontWeight={700}>
        {label}:
      </StyledText>
      <StyledText level="body-md" color={palette.charcoal}>
        {value instanceof Date
          ? value.toLocaleString()
          : typeof value === "boolean"
          ? value
            ? "Yes"
            : "No"
          : value}
      </StyledText>
    </Box>
  );
};

const TicketTypeDisplay: React.FC<{ ticketType: any }> = ({ ticketType }) => {
  const palette = usePalette();

  return (
    <Box sx={{ mt: 1, borderRadius: 1 }}>
      <Field label="Name" value={ticketType.name} />
      <Field label="Price" value={`${ticketType.price} SEK`} />
      <Field label="Description" value={ticketType.description} />
    </Box>
  );
};

const TicketReleaseDisplay: React.FC<{ release: ITicketReleasePostReq }> = ({
  release,
}) => {
  const palette = usePalette();

  return (
    <Box sx={{ mt: 1, borderRadius: 1 }}>
      <Field label="Name" value={release.name} />
      <Field label="Description" value={release.description} />
      <Field label="Open" value={new Date(release.open)} />
      <Field label="Close" value={new Date(release.close)} />
      <Field
        label="Ticket Release Method"
        value={mapTicketReleaseMethod(release.ticket_release_method_id)}
      />
      {mapTicketReleaseMethod(release.ticket_release_method_id) ===
        ITicketReleaseMethodType.SELECTIVE && (
        <Field label="Selection Criteria" value={release.method_description!} />
      )}
      {mapTicketReleaseMethod(release.ticket_release_method_id) ===
        ITicketReleaseMethodType.FCFS_LOTTERY && (
        <Field
          label="Lottery Window Duration"
          value={new Date(release.open_window_duration!)}
        />
      )}
      <Field
        label="Max Tickets per User"
        value={release.max_tickets_per_user}
      />
      <Field label="Notification Method" value={release.notification_method} />
      <Field label="Cancellation Policy" value={release.cancellation_policy} />
      {release.is_reserved && release.promo_code && (
        <Field label="Promo Code" value={release.promo_code} />
      )}
      {release.tickets_available && (
        <Field label="Tickets Available" value={release.tickets_available} />
      )}
      {release.payment_deadline && (
        <Field
          label="Payment Deadline"
          value={new Date(release.payment_deadline)}
        />
      )}
      {release.reserve_payment_duration && (
        <Field
          label="Reserve Payment Duration"
          value={release.reserve_payment_duration}
        />
      )}
      {release.allocation_cut_off && (
        <Field
          label="Allocation Cut Off"
          value={new Date(release.allocation_cut_off)}
        />
      )}
    </Box>
  );
};

const EventDataDisplay: React.FC<{ eventData: IAIEventData }> = ({
  eventData,
}) => {
  const palette = usePalette();
  const { t } = useTranslation();

  const renderEventDetails = (event: IEventPostReq) => (
    <Box sx={{ mt: 1, borderRadius: 1 }}>
      <Field label="Name" value={event.name} />
      <Field label="Category" value={event.category} />
      <Field label="Description" value={event.description} />
      <Field label="Date" value={new Date(event.date)} />
      {event.end_date && (
        <Field label="End Date" value={new Date(event.end_date)} />
      )}
      <Field label="Location" value={event.location} />
      <Field label="Organization ID" value={event.organization_id} />
      <Field label="Is Private" value={event.is_private} />
      <Field
        label="Collect Food Preferences"
        value={event.collect_food_preferences}
      />
      {event.language && (
        <Field label="Language" value={event.language.toUpperCase()} />
      )}
    </Box>
  );

  // Rest of EventDataDisplay remains the same
  return (
    <Box
      sx={{
        mt: 2,
        p: 3,
        bgcolor: palette.white,
        borderRadius: 2,
        border: `1px solid ${palette.greyLight}`,
        boxShadow: `0 4px 12px ${palette.greyLight}`,
      }}
    >
      <StyledText
        level="h5"
        fontWeight={700}
        color={palette.primaryDark}
        sx={{ mb: 2 }}
      >
        Generated Event Details
      </StyledText>

      <Box sx={{ mt: 2, mb: 1, pl: 1 }}>
        <StyledText
          level="body-lg"
          fontWeight={600}
          fontSize={22}
          color={palette.primaryMain}
          sx={{ mb: 1 }}
        >
          Event:
        </StyledText>
        {renderEventDetails(eventData.event!)}
      </Box>

      <Box sx={{ mt: 2, mb: 1, pl: 1 }}>
        <StyledText
          level="body-lg"
          fontWeight={600}
          fontSize={22}
          color={palette.primaryMain}
          sx={{ mb: 1 }}
        >
          Ticket Release:
        </StyledText>
        <TicketReleaseDisplay release={eventData.ticket_release!} />
      </Box>

      <Box sx={{ mt: 2, mb: 1, pl: 1 }}>
        <StyledText
          level="body-lg"
          fontWeight={600}
          fontSize={22}
          color={palette.primaryMain}
          sx={{ mb: 1 }}
        >
          Ticket Types:
        </StyledText>
        {eventData.ticket_types!.map((ticketType, index) => (
          <Grid container key={index} sx={{ mb: 1.5 }}>
            <Card
              variant="outlined"
              sx={{
                width: "100%",
                p: 0,
                bgcolor: palette.white,
                borderRadius: 8,
              }}
            >
              <Box sx={{ pl: 2 }}>
                <TicketTypeDisplay ticketType={ticketType} />
              </Box>
            </Card>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default EventDataDisplay;
