import { IconButton, useTheme } from "@mui/joy";
import { useThemeContext } from "../../theme/theme_context";
import usePalette from "../../theme/use_palette";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { useMediaQuery } from "@mui/material";

export const ThemeToggleButton: React.FC = () => {
  const { theme, toggleTheme } = useThemeContext();
  const palette = usePalette();

  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("md"));

  return (
    <IconButton
      onClick={toggleTheme}
      sx={{
        borderRadius: "50%",
        width: "38px",
        height: "38px",
        backgroundColor: "transparent",
        transition: "background-color 0.3s ease, transform 0.2s ease",
        "&:hover": {
          transform: "scale(1.05)",
          backgroundColor: palette.white,
        },
        boxShadow: palette.primaryMain,
      }}
    >
      {theme === "dark" ? (
        <LightModeIcon
          sx={{
            color: palette.black,
          }}
        />
      ) : (
        <DarkModeIcon
          sx={{
            color: palette.black,
          }}
        />
      )}
    </IconButton>
  );
};
