import axios from "axios";
import { toast } from "react-toastify";
import { IGuestCustomer, ITicketOrder } from "../types";
import { ROUTES } from "../routes/def";

export const handlePay = async (
  ticketOrder: ITicketOrder,
  event_ref_id: string,
  isGuestCustomer: boolean,
  guestCustomer?: IGuestCustomer | null,
  event?: React.FormEvent<HTMLFormElement>
) => {
  if (event) event.preventDefault();

  const url =
    process.env.REACT_APP_BACKEND_URL +
    (isGuestCustomer
      ? `/guest-customer/${guestCustomer?.id}/payments/events/${event_ref_id}/order/create?request_token=${guestCustomer?.request_token}`
      : `/payments/events/${event_ref_id}/order/create`);

  // Request to create a payment intent
  try {
    const response = await axios.post(
      url,
      {
        ticket_order_id: ticketOrder.id,
      },
      {
        withCredentials: true,
      }
    );

    if (response.data.order.is_free) {
      // Redirect to the success page
      window.location.href =
        ROUTES.PROFILE + "?tab=tickets&ticket_order_id=" + ticketOrder.id;
      return;
    }

    const paymentPageLink = response.data.order.paymentPageLink;
    window.location.href = paymentPageLink;

    // You can now open a modal dialog with the payment details or proceed as below
  } catch (error: any) {
    const errorMessage = error.response.data.error || "An error occurred";
    toast.error(errorMessage);
    console.error("Error creating payment intent:", error);
  }
};
