import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INetworkInvite, NetworkInviteState } from "../../../types";

// Initial state for the network invites
const initialState: NetworkInviteState = {
  invites: [],
  loading: false,
  error: {
    status: null,
    message: null,
  },
};

const networkInvitesSlice = createSlice({
  name: "networkInvites",
  initialState,
  reducers: {
    // Action to request network invites
    getNetworkInvitesRequest: (
      state,
      action: PayloadAction<{
        networkID: number;
      }>
    ) => {
      if (state.loading) return;
      state.loading = true;
      state.error = null; // Clear any existing errors
    },
    // Action for successful retrieval of network invites
    getNetworkInvitesSuccess: (
      state,
      action: PayloadAction<INetworkInvite[]>
    ) => {
      state.loading = false;
      state.invites = action.payload;
    },
    // Action for failed retrieval of network invites
    getNetworkInvitesFailure: (
      state,
      action: PayloadAction<{
        status: number;
        message: string;
      }>
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Optional: Clear invites if needed
    clearNetworkInvites: (state) => {
      state.invites = [];
      state.error = null;
    },
    removeNetworkInviteRequest: (
      state,
      action: PayloadAction<{ token: string }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    removeNetworkInviteSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.invites = state.invites.filter(
        (invite) => invite.token !== action.payload
      );
    },
    removeNetworkInviteFailure: (
      state,
      action: PayloadAction<{
        status: number;
        message: string;
      }>
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Export actions to be used in components
export const {
  getNetworkInvitesRequest,
  getNetworkInvitesSuccess,
  getNetworkInvitesFailure,
  clearNetworkInvites,
  removeNetworkInviteRequest,
  removeNetworkInviteSuccess,
  removeNetworkInviteFailure,
} = networkInvitesSlice.actions;

// Export the reducer to be included in the store
export default networkInvitesSlice.reducer;
