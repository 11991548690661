import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/joy/MenuItem";
import StyledText from "../text/styled_text";

interface MenuItemComponentProps {
  text: string;
  route: string;
  handleClose: () => void;
  textColor: string;
}

const MenuItemComponent: React.FC<MenuItemComponentProps> = ({
  text,
  route,
  handleClose,
  textColor,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <MenuItem
      onClick={() => {
        handleClose();
        navigate(route);
      }}
    >
      <StyledText
        level="body-sm"
        color={textColor}
        fontSize={18}
        fontWeight={500}
      >
        {t(text)}
      </StyledText>
    </MenuItem>
  );
};

export default MenuItemComponent;
