import { Fragment, useEffect } from "react";
import StyledText from "../text/styled_text";
import { Box, Button, Link } from "@mui/joy";
import usePalette from "../../theme/use_palette";

const ConsentPreferences = () => {
  const palette = usePalette();

  const handleClick = () => {
    const termlyElement = document.querySelector(".termly-display-preferences");

    if (termlyElement) {
      (termlyElement as HTMLElement).click();
    }
  };

  return (
    <Fragment>
      <a href="#" className="termly-display-preferences">
        Consent Preferences
      </a>
    </Fragment>
  );
};

export default ConsentPreferences;
