import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, UserState } from "../../types";
import { REHYDRATE } from "redux-persist";
import { faL } from "@fortawesome/free-solid-svg-icons";

export interface IUpdateProfileData {
  first_name: string;
  last_name: string;
  phone_number: string;
}

const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
  deactivateInitiated: false,
};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    currentUserRequest: (state) => {
      if (!state.user) {
        state.loading = true;
      }
    },
    currentUserSuccess: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    currentUserFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateUsersProfileRequest: (
      state,
      action: PayloadAction<IUpdateProfileData>
    ) => {
      state.loading = true;
    },
    updateUsersProfileSuccess: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateUsersProfileFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    deactivateAccountRequest: (state) => {
      state.loading = true;
      state.deactivateInitiated = false;
    },
    deactivateAccountSuccess: (state) => {
      state.loading = false;
      state.error = null;
      state.deactivateInitiated = true;
    },
    deactivateAccountFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.deactivateInitiated = false;
    },
    resetUser: (state) => {
      state.user = null;
      state.deactivateInitiated = false;
    },
  },
  extraReducers: {
    [REHYDRATE]: (state, action) => {
      return {
        ...state,
        ...(action.payload ? action.payload.auth : {}),
        loading: false,
      };
    },
  },
});

export const {
  currentUserRequest,
  currentUserSuccess,
  currentUserFailure,
  updateUsersProfileRequest,
  updateUsersProfileSuccess,
  updateUsersProfileFailure,
  deactivateAccountRequest,
  deactivateAccountSuccess,
  deactivateAccountFailure,
  resetUser,
} = userSlice.actions;

export default userSlice.reducer;
