import { call, put, takeLatest } from "redux-saga/effects";

import {
  fetchCustomizationFailure,
  fetchCustomizationStart,
  fetchCustomizationSuccess,
  setOrganization,
  updateCustomizationFailure,
  updateCustomizationStart,
  updateCustomizationSuccess,
} from "../../../features/manager/organization/organizationStoreCustomization";
import { ApiResponse, fetchApi, putApi } from "../../../../utils/api/api";
import {
  IOrganization,
  IOrganizationStoreCustomization,
} from "../../../../types";
import ApiRoutes from "../../../../routes/backend_routes";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Worker Saga: Fetch Store Customization
function* fetchCustomizationSaga(
  action: PayloadAction<{
    organizationID: number;
  }>
): Generator<any, void, any> {
  try {
    const { organizationID } = action.payload;
    const url = ApiRoutes.generateRoute(
      ApiRoutes.ORGANIZATION_STORE_CUSTOMIZATION,
      {
        organizationID: organizationID,
      }
    );
    const response: ApiResponse<{
      organization: IOrganization;
      store_customization: IOrganizationStoreCustomization;
    }> = yield call(fetchApi, url, true, true);
    yield put(fetchCustomizationSuccess(response.data.store_customization));
    yield put(setOrganization(response.data.organization));
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || error.message;
    toast.error(errorMessage);
    yield put(fetchCustomizationFailure(error.message));
  }
}

// Worker Saga: Update Store Customization
function* updateCustomizationSaga(
  action: PayloadAction<{
    organizationID: number;
    data: IOrganizationStoreCustomization;
  }>
): Generator<any, void, any> {
  try {
    const { organizationID, data } = action.payload;

    const url = ApiRoutes.generateRoute(
      ApiRoutes.ORGANIZATION_STORE_CUSTOMIZATION,
      {
        organizationID: organizationID,
      }
    );

    const response: ApiResponse<{
      store_customization: IOrganizationStoreCustomization;
    }> = yield call(putApi, url, data, true, true);

    toast.success("Customization updated successfully");

    yield put(updateCustomizationSuccess(response.data.store_customization));
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || error.message;
    toast.error(errorMessage);
    yield put(updateCustomizationFailure(errorMessage));
  }
}

// Watcher Saga: Watches for dispatched actions
function* organizationCustomizationSaga() {
  yield takeLatest(fetchCustomizationStart.type, fetchCustomizationSaga);
  yield takeLatest(updateCustomizationStart.type, updateCustomizationSaga);
}

export default organizationCustomizationSaga;
