import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventState, IEvent, IEventFinancialDetailsPostReq } from "../../types";

const initialState: { event: IEvent | null; loading: boolean } = {
  event: null,
  loading: false,
};

const eventFinancialSlice = createSlice({
  name: "eventFinancial",
  initialState,
  reducers: {
    updateEventFinancialRequest: (
      state,
      action: PayloadAction<{
        eventID: number;
        financialDetails: IEventFinancialDetailsPostReq;
      }>
    ) => {
      state.loading = true;
    },
    updateEventFinancialSuccess: (state, action: PayloadAction<null>) => {
      state.loading = false;
      state.event = action.payload;
    },
    updateEventFinancialFailure: (
      state,
      action: PayloadAction<{
        error: string;
      }>
    ) => {
      state.loading = false;
    },
  },
});

export const {
  updateEventFinancialRequest,
  updateEventFinancialSuccess,
  updateEventFinancialFailure,
} = eventFinancialSlice.actions;

export default eventFinancialSlice.reducer;
