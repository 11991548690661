import React, { forwardRef, useEffect } from "react";
import { Box, Stack } from "@mui/joy";
import usePalette from "../../../theme/use_palette";
import StyledText from "../../text/styled_text";
import { format } from "date-fns";
import EventDataDisplay, {
  mapTicketReleaseMethod,
} from "../../manager/tessera_ai/ai_event_details";
import {
  AiChatSessionValues,
  CompleteEventWorkflowPostReq,
  IAIChatSessionMessage,
  IAIEventData,
  IEventForm,
  ITicketReleaseForm,
  ITicketTypeForm,
} from "../../../types";
import { useTranslation } from "react-i18next";
import { adjustTransparency } from "../../../utils/manager/color";
import { motion } from "framer-motion";
import {
  clearEventForm,
  resetCompletely,
  setEventForm,
  setFromAI,
  setTicketReleaseForm,
} from "../../../redux/features/eventCreationSlice";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PlaceOption } from "../../forms/input_types";
import StyledButton from "../../buttons/styled_button";
import { ROUTES } from "../../../routes/def";
import { setTicketTypes } from "../../../redux/features/ticketTypeCreationSlice";
import ReactMarkdown from "react-markdown";

interface MessageBubbleProps {
  message: IAIChatSessionMessage;
}

const MessageBubble = forwardRef<HTMLDivElement, MessageBubbleProps>(
  ({ message }, ref) => {
    const { t } = useTranslation();
    const palette = usePalette();
    const dispatch: AppDispatch = useDispatch();
    const [eventData, setEventData] = React.useState<IAIEventData | null>(null);
    const navigate = useNavigate();

    // Animation variants
    const variants = {
      hidden: { opacity: 0, y: 40 },
      visible: { opacity: 1, y: 0 },
    };


    useEffect(() => {
      if (
        message.data &&
        message.message_type === AiChatSessionValues.EVENT_GENERATION
      ) {
        setEventData(message.data!);
      }
    }, [message.data]);

    const handleUseEvent = () => {
      if (!eventData) return;

      const eventForm: Partial<IEventForm> = {
        ...eventData.event,
        date: format(new Date(eventData.event!.date), "yyyy-MM-dd HH:mm"),
        end_date: eventData.event!.end_date
          ? format(new Date(eventData.event!.end_date), "yyyy-MM-dd HH:mm")
          : undefined,
        location: {
          label: eventData.event!.location,
          value: eventData.event!.location,
        },
      };

      const ticketReleaseForm: Partial<ITicketReleaseForm> = {
        ...eventData.ticket_release,
        payment_deadline:
          eventData.ticket_release!.payment_deadline ||
          format(new Date(), "yyyy-MM-dd"),
        reserve_payment_duration:
          eventData.ticket_release!.reserve_payment_duration || "",
        allocation_cut_off: eventData.ticket_release!.allocation_cut_off || "",
        notification_method:
          eventData.ticket_release!.notification_method.toUpperCase() || "",
        ticket_release_method_name: mapTicketReleaseMethod(
          eventData.ticket_release!.ticket_release_method_id
        ),
        open: format(
          new Date(eventData.ticket_release!.open),
          "yyyy-MM-dd HH:mm"
        ),
        close: format(
          new Date(eventData.ticket_release!.close),
          "yyyy-MM-dd HH:mm"
        ),
        is_saved: true,
      };

      const ticketTypes: ITicketTypeForm[] = eventData.ticket_types!.map(
        (ticketType) => {
          return {
            ...ticketType,
          } as ITicketTypeForm;
        }
      );

      // Dispatch event data to eventCreation slice
      dispatch(resetCompletely());
      dispatch(
        setFromAI({
          eventForm: eventForm,
          ticketReleaseForm: ticketReleaseForm,
          ticketTypes: ticketTypes,
        })
      );
      dispatch(setTicketTypes(ticketTypes));
      // Navigate to the event creation form
      setTimeout(() => {
        navigate(ROUTES.CREATE_EVENT);
      }, 500);
    };

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ duration: 0.5 }}
      >
        <Box
          sx={{
            mb: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: message.role === "user" ? "flex-end" : "flex-start",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
            <StyledText level="body-sm" fontWeight={600} color={palette.black}>
              {message.role === "assistant"
                ? t("manager.ai.response")
                : message.sent_by?.first_name || t("manager.ai.you")}
            </StyledText>
            <StyledText level="body-sm" color={palette.greyLight} fontSize={13}>
              {format(new Date(message.created_at), "dd/MM/yyyy HH:mm")}
            </StyledText>
          </Stack>
          <Box
            sx={{
              p: message.role === "user" ? 2 : 0,
              borderRadius: message.role === "user" ? 8 : 0,
              bgcolor:
                message.role === "user"
                  ? adjustTransparency(palette.black, 0.1)
                  : "transparent",
              maxWidth: message.role === "user" ? "70%" : "100%",
            }}
          >
            {message.role === "user" ? (
              <StyledText level="body-md" fontSize={17}>
                {message.content}
              </StyledText>
            ) : (
              <ReactMarkdown
                components={{
                  h1: ({ children }) => (
                    <StyledText level="h2" fontSize={28} sx={{ mt: 2, mb: 1 }}>
                      {children}
                    </StyledText>
                  ),
                  h2: ({ children }) => (
                    <StyledText level="h3" fontSize={22} sx={{ mt: 2, mb: 1 }}>
                      {children}
                    </StyledText>
                  ),
                  h3: ({ children }) => (
                    <StyledText level="h4" fontSize={18} sx={{ mt: 2, mb: 1 }}>
                      {children}
                    </StyledText>
                  ),
                  h4: ({ children }) => (
                    <StyledText
                      level="h5"
                      fontSize={16}
                      sx={{ mt: 1.5, mb: 0.75 }}
                    >
                      {children}
                    </StyledText>
                  ),
                  h5: ({ children }) => (
                    <StyledText
                      level="h6"
                      fontSize={15}
                      sx={{ mt: 1.5, mb: 0.75 }}
                    >
                      {children}
                    </StyledText>
                  ),
                  h6: ({ children }) => (
                    <StyledText
                      level="h6"
                      fontSize={14}
                      sx={{ mt: 1.5, mb: 0.75 }}
                    >
                      {children}
                    </StyledText>
                  ),
                  p: ({ children }) => (
                    <StyledText level="body-md" fontSize={17} sx={{ my: 1 }}>
                      {children}
                    </StyledText>
                  ),
                }}
              >
                {message.content}
              </ReactMarkdown>
            )}
            {/* Display "Use Event" button if eventData is present */}
            {message.role === "assistant" && eventData?.event && (
              <Box sx={{ mt: 2 }}>
                <StyledButton
                  onClick={handleUseEvent}
                  size="sm"
                  bgColor={"transparent"}
                >
                  {t("manager.ai.use_event_button")}
                </StyledButton>
              </Box>
            )}
            {message.role === "assistant" &&
              message.message_type === AiChatSessionValues.EVENT_GENERATION &&
              eventData?.event && (
                <EventDataDisplay eventData={message.data!} />
              )}
          </Box>
        </Box>
      </motion.div>
    );
  }
);

export default MessageBubble;
