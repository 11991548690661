import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IOrganization,
  IOrganizationStoreCustomization,
} from "../../../../types";

interface IOrganizationCustomizationState {
  organization: IOrganization | null;
  customization: IOrganizationStoreCustomization;
  loading: boolean;
  error: string | null;
}

const initialState: IOrganizationCustomizationState = {
  organization: null,
  customization: {
    backgroundColor: "",
    brandColor: "",
    accentColor: "",
    rectShape: "",
    fontType: "",
    logoUrl: "",
    iconUrl: "",
    description: "",
    short_description: "",
  },
  loading: false,
  error: null,
};

const organizationCustomizationSlice = createSlice({
  name: "organizationCustomization",
  initialState,
  reducers: {
    fetchCustomizationStart: (
      state,
      action: PayloadAction<{
        organizationID: number;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    fetchCustomizationSuccess: (
      state,
      action: PayloadAction<IOrganizationStoreCustomization>
    ) => {
      state.customization = action.payload;
      state.loading = false;
    },
    fetchCustomizationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCustomizationStart: (
      state,
      action: PayloadAction<{
        organizationID: number;
        data: IOrganizationStoreCustomization;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    updateCustomizationSuccess: (
      state,
      action: PayloadAction<IOrganizationStoreCustomization>
    ) => {
      state.customization = action.payload;
      state.loading = false;
    },
    updateCustomizationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setOrganization: (state, action: PayloadAction<IOrganization | null>) => {
      state.organization = action.payload;
    },
  },
});

export const {
  fetchCustomizationStart,
  fetchCustomizationSuccess,
  fetchCustomizationFailure,
  updateCustomizationStart,
  updateCustomizationSuccess,
  updateCustomizationFailure,
  setOrganization,
} = organizationCustomizationSlice.actions;

export default organizationCustomizationSlice.reducer;
