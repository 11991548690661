import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { IGuestCustomer, ITicketOrder } from "../../../types";
import StyledButton from "../../buttons/styled_button";
import {
  Box,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import StyledText from "../../text/styled_text";
import usePalette from "../../../theme/use_palette";
import CheckoutForm from "./form";
import { useTranslation } from "react-i18next";
import { useTicketCost } from "../../events/payments/use_cost";
import { handlePay } from "../../../handlers/paymentHandler";

let stripePromise: any;

interface PaymentProps {
  ticketOrder: ITicketOrder;
  isGuestCustomer?: boolean;
  guestCustomer?: IGuestCustomer;
  payDirect?: boolean; // Used to determine if the payment should be made directly, if it is, trigger the payment immediately
}

const Payment: React.FC<PaymentProps> = ({
  ticketOrder,
  isGuestCustomer = false,
  guestCustomer,
  payDirect = false,
}) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const tickets = ticketOrder.tickets;
  const { totalCost } = useTicketCost(ticketOrder.tickets);

  const { t } = useTranslation();
  const palette = usePalette();

  useEffect(() => {
    if (payDirect) {
      handlePay(
        ticketOrder,
        ticketOrder.ticket_release?.event?.reference_id!,
        isGuestCustomer,
        guestCustomer
      );
    }
  }, []);

  // Return URL depends on whether the user is a guest or not
  // For guest is the exact same, with query param and everything
  const returnUrl = isGuestCustomer
    ? window.location.href
    : process.env.REACT_APP_BASE_URL + "/profile/tickets";

  return (
    <>
      <StyledButton
        size="lg"
        bgColor={palette.primaryMain}
        color={palette.black}
        fs={20}
        onClick={(e) => {
          handlePay(
            ticketOrder,
            ticketOrder.ticket_release?.event?.reference_id!,
            isGuestCustomer,
            guestCustomer,
            e
          );
        }}
      >
        {t("tickets.pay_button")}
      </StyledButton>
      {clientSecret && (
        <Modal open={true} onClose={() => setClientSecret(null)}>
          <ModalDialog color="primary" size="sm" variant="outlined">
            <ModalClose onClick={() => setClientSecret(null)} />
            <DialogTitle>
              <StyledText level="h3" color={palette.primaryMain} fontSize={24}>
                {t("tickets.payment.title")}
              </StyledText>
            </DialogTitle>
            <DialogContent>
              <Box>
                <StyledText level="body-md" color={palette.charcoal}>
                  <b>{totalCost} SEK</b>
                </StyledText>
                <Box mt={2}>
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret,
                      appearance: {
                        theme: "stripe",
                        // customize your appearance options here
                      },
                    }}
                  >
                    <CheckoutForm tickets={tickets} returnURL={returnUrl} />
                  </Elements>
                </Box>
              </Box>
            </DialogContent>
          </ModalDialog>
        </Modal>
      )}
    </>
  );
};

export default Payment;
