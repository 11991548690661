import { ApiResponse, deleteApi, postApi } from "../../../../utils/api/api";
import ApiRoutes from "../../../../routes/backend_routes";

interface ImportContactsPayload {
  emails: string[];
}

export const importContacts = async (payload: ImportContactsPayload) => {
  const response: ApiResponse<void> = await postApi(
    ApiRoutes.MANAGER_CONTACT_DATABASE_IMPORT,
    payload,
    true,
    true
  );
  return response;
};

export const deleteContacts = async (payload: ImportContactsPayload) => {
  const response: ApiResponse<void> = await deleteApi(
    ApiRoutes.MANAGER_CONTACT_DATABASE,
    payload,
    true,
    true
  );
  return response;
};
