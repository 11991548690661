import { useState, useEffect } from "react";

const useDrawerHeight = () => {
  const [drawerHeight, setDrawerHeight] = useState("100vh");

  const handleScroll = () => {
    const footerElement = document.getElementById("footer");
    if (footerElement) {
      const footerRect = footerElement.getBoundingClientRect();
      const footerVisibleHeight = Math.max(
        0,
        window.innerHeight - footerRect.top
      );

      const newDrawerHeight = `calc(100vh - ${footerVisibleHeight}px)`;
      setDrawerHeight(newDrawerHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call to set the height

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return drawerHeight;
};

export default useDrawerHeight;
