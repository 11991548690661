import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { ITicketReleaseMethod } from "../../types";
import axios from "axios";
import {
  getTicketReleaseMethodsFailure,
  getTicketReleaseMethodsRequest,
  getTicketReleaseMethodsSuccess,
} from "../features/ticketReleaseMethodsSlice";
import { ApiResponse, fetchApi } from "../../utils/api/api";

function* fetchTicketReleaseMethods(): Generator<any, void, any> {
  try {
    const response: ApiResponse<{
      ticket_release_methods: ITicketReleaseMethod[];
    }> = yield call(
      fetchApi,
      `${process.env.REACT_APP_BACKEND_URL}/ticket-release-methods`,
      true,
      true
    );

    if (response.status === "success") {
      yield put(
        getTicketReleaseMethodsSuccess(response.data.ticket_release_methods)
      );
    } else {
      yield put(getTicketReleaseMethodsFailure("Something went wrong!"));
    }
  } catch (error: any) {
    yield put(getTicketReleaseMethodsFailure(error.message));
  }
}

export function* watchFetchTicketReleaseMethods() {
  yield takeLatest(
    getTicketReleaseMethodsRequest.type,
    fetchTicketReleaseMethods
  );
}
