// utils/calendar.ts
import { format, addHours } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export interface ICalendarEvent {
  name: string;
  description: string;
  location?: string;
  date: Date | string;
  end_date?: Date | string;
}

const getDefaultEndDate = (startDate: Date | string): Date => {
  const dateObject =
    typeof startDate === "string" ? new Date(startDate) : startDate;
  return addHours(dateObject, 1);
};

export const formatDateForCalendar = (date: Date | string): string => {
  const dateObject = typeof date === "string" ? new Date(date) : date;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return formatInTimeZone(dateObject, timeZone, "yyyyMMdd'T'HHmmss");
};

export const getGoogleCalendarUrl = (event: ICalendarEvent): string => {
  const startDate = formatDateForCalendar(event.date);
  const endDate = event.end_date
    ? formatDateForCalendar(event.end_date)
    : formatDateForCalendar(getDefaultEndDate(event.date));

  const params = new URLSearchParams({
    action: "TEMPLATE",
    text: event.name,
    details: event.description,
    location: event.location || "",
    dates: `${startDate}/${endDate}`,
  });

  return `https://calendar.google.com/calendar/render?${params.toString()}`;
};

export const getOutlookCalendarUrl = (event: ICalendarEvent): string => {
  const startDate = formatDateForCalendar(event.date);
  const endDate = event.end_date
    ? formatDateForCalendar(event.end_date)
    : formatDateForCalendar(getDefaultEndDate(event.date));

  const params = new URLSearchParams({
    path: "/calendar/action/compose",
    rru: "addevent",
    startdt: startDate,
    enddt: endDate,
    subject: event.name,
    body: event.description,
    location: event.location || "",
  });

  return `https://outlook.office.com/calendar/0/deeplink/compose?${params.toString()}`;
};

export const getICalendarUrl = (event: ICalendarEvent): string => {
  const startDate = formatDateForCalendar(event.date);
  const endDate = event.end_date
    ? formatDateForCalendar(event.end_date)
    : formatDateForCalendar(getDefaultEndDate(event.date));

  const icsContent = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "BEGIN:VEVENT",
    `DTSTART:${startDate}`,
    `DTEND:${endDate}`,
    `SUMMARY:${event.name}`,
    `DESCRIPTION:${event.description}`,
    `LOCATION:${event.location || ""}`,
    "END:VEVENT",
    "END:VCALENDAR",
  ].join("\n");

  const blob = new Blob([icsContent], {
    type: "text/calendar;charset=utf-8",
  });
  return URL.createObjectURL(blob);
};
