import React, { useState, useEffect } from "react";
import NavigationBar from "../navigation";
import { Box, IconButton, useTheme } from "@mui/joy";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Footer from "./footer";
import usePalette from "../../theme/use_palette";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { INavigationBarOptions } from "../../types";
import useShowMobileNav from "../../hooks/nav/show_mobile_nav_hook";

interface TesseraWrapperProps {
  children: React.ReactNode;
  defaultColors?: boolean;
  navOptions?: INavigationBarOptions;
}

export const SHOW_SCROLL_UP_THRESHOLD = 800;

const MUITesseraWrapper: React.FC<TesseraWrapperProps> = ({
  children,
  defaultColors = false,
  navOptions,
}) => {
  const [showScroll, setShowScroll] = useState(false);
  const theme = useTheme();
  const useMobileNav = useShowMobileNav();
  const palette = usePalette();

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > SHOW_SCROLL_UP_THRESHOLD) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= SHOW_SCROLL_UP_THRESHOLD) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <NavigationBar
        options={navOptions}
        shouldUseDefaultColor={defaultColors}
        isJoyTheme={false}
      />

      <Box
        sx={{ minHeight: "100vh", pb: 20 }}
        id="tessera-wrapper"
        style={{
          backgroundColor: palette.offWhite,
          overflow: "hidden",
          paddingTop: "32px",
          marginTop: useMobileNav
            ? "0"
            : navOptions?.isDashboard
            ? "16px"
            : "64px",
        }}
      >
        {children}
      </Box>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: showScroll ? 1 : 0, y: showScroll ? 0 : 50 }}
        transition={{ duration: 0.5 }}
        style={{ position: "fixed", top: "90px", right: "30px", zIndex: 1000 }}
      >
        <IconButton
          sx={{
            backgroundColor: palette.primaryMain,
            color: "#fff",
            "&:hover": {
              backgroundColor: palette.primaryMain,
            },
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
          onClick={scrollTop}
          aria-label="Scroll to top"
        >
          <ArrowUpwardIcon />
        </IconButton>
      </motion.div>

      <Footer />
    </>
  );
};

export default MUITesseraWrapper;
