import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISelectedAddon, ITicketOrder, ITicketType } from "../../types";
import { ITicketOrderRequest } from "../sagas/ticketOrderSaga";
import { v4 } from "uuid";

// Update ShoppingCartItem interface
export interface ShoppingCartItem {
  id: string; // Unique identifier for each cart item
  ticket: ITicketType;
  addons: ISelectedAddon[]; // Addons selected for this ticket
}

export interface ShoppingCartState {
  items: ShoppingCartItem[];
  ticketOrders: ITicketOrder[];
  loading: boolean;
  error: string | null;
  newlyCreatedTicketOrder: ITicketOrder | null;
  postSuccess: boolean;
  currentTicketReleaseId: number | null; // Add this line
  currentEventId: number | null;
  isCartModalOpen: boolean; // Add this line
  isConfirmSwitchModalOpen: boolean; // Add this line
  pendingTicketToAdd: ITicketType | null; // Add this line
}

const initialState: ShoppingCartState = {
  items: [],
  ticketOrders: [],
  loading: false,
  error: null,
  newlyCreatedTicketOrder: null,
  postSuccess: false,
  currentTicketReleaseId: null, // Initialize
  currentEventId: null,
  isCartModalOpen: false, // Initialize
  isConfirmSwitchModalOpen: false, // Initialize
  pendingTicketToAdd: null, // Initialize
};

export const ticketOrderSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    addTicket: (state, action: PayloadAction<ITicketType>) => {
      const newItem: ShoppingCartItem = {
        id: v4(),
        ticket: action.payload,
        addons: [],
      };
      state.items.push(newItem);
    },
    removeTicket: (state, action: PayloadAction<ITicketType>) => {
      const index = state.items.findIndex(
        (item) => item.ticket.id === action.payload.id
      );
      if (index !== -1) {
        state.items.splice(index, 1);
      }
    },
    updateCartItemAddons: (
      state,
      action: PayloadAction<{ cartItemId: string; addons: ISelectedAddon[] }>
    ) => {
      const { cartItemId, addons } = action.payload;
      const item = state.items.find((item) => item.id === cartItemId);
      if (item) {
        item.addons = addons;
      }
    },
    setCurrentTicketReleaseId: (
      state,
      action: PayloadAction<{
        ticketReleaseId: number;
        eventId: number;
      }>
    ) => {
      state.currentTicketReleaseId = action.payload.ticketReleaseId;
      state.currentEventId = action.payload.eventId;
    },
    switchEvent: (state, action: PayloadAction<number>) => {
      state.currentEventId = action.payload;
      state.items = [];
    },
    clearCart: (state) => {
      state.items = [];
      state.currentTicketReleaseId = null;
    },
    openCartModal: (state) => {
      state.isCartModalOpen = true;
    },
    closeCartModal: (state) => {
      state.isCartModalOpen = false;
    },
    promptSwitchRelease: (state, action: PayloadAction<ITicketType>) => {
      state.isConfirmSwitchModalOpen = true;
      state.pendingTicketToAdd = action.payload;
    },
    confirmSwitchRelease: (state) => {
      // Clear the current cart items
      state.items = [];

      // Set the current ticket release ID to the pending ticket's release ID
      state.currentTicketReleaseId =
        state.pendingTicketToAdd!.ticket_release_id!;

      // Add the pending ticket as a new cart item (without quantity)
      state.items.push({
        id: v4(),
        ticket: state.pendingTicketToAdd!,
        addons: [],
      });

      // Close the switch confirmation modal and reset pending ticket
      state.isConfirmSwitchModalOpen = false;
      state.pendingTicketToAdd = null;
    },

    cancelSwitchRelease: (state) => {
      state.isConfirmSwitchModalOpen = false;
      state.pendingTicketToAdd = null;
    },
    // Existing actions
    postTicketOrderRequest: (
      state,
      action: PayloadAction<{
        promoCodes: string[];
        tickeOrderReq: ITicketOrderRequest;
        addons: ISelectedAddon[];
        eventId: number;
      }>
    ) => {
      state.loading = true;
      state.postSuccess = false;
    },
    postTicketOrderSuccess: (state, action: PayloadAction<ITicketOrder>) => {
      state.loading = false;
      state.items = [];
      state.error = null;
      state.postSuccess = true;
      state.newlyCreatedTicketOrder = action.payload;
    },
    postTicketOrderFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.postSuccess = false;
    },
    resetPostSuccess: (state) => {
      state.postSuccess = false;
    },
    resetNewlyCreatedTicketOrder: (state) => {
      state.newlyCreatedTicketOrder = null;
    },
    resetError: (state) => {
      state.error = null;
    },
    resetCurrentTicketReleaseId: (state) => {
      state.currentTicketReleaseId = null;
      state.items = [];
    },
  },
});

export const {
  addTicket,
  removeTicket,
  updateCartItemAddons,
  setCurrentTicketReleaseId,
  clearCart,
  openCartModal,
  closeCartModal,
  promptSwitchRelease,
  confirmSwitchRelease,
  cancelSwitchRelease,
  postTicketOrderRequest,
  postTicketOrderSuccess,
  postTicketOrderFailure,
  resetPostSuccess,
  resetNewlyCreatedTicketOrder,
  resetError,
  resetCurrentTicketReleaseId,
  switchEvent,
} = ticketOrderSlice.actions;

export default ticketOrderSlice.reducer;
