import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "../../../../utils/api/api";
import {
  deleteContacts,
  importContacts,
} from "../../axios_calls/contact_database/contacts_service";
import {
  deleteContactsFailure,
  deleteContactsRequest,
  deleteContactsSuccess,
  getNetworkContactsRequest,
  importContactsFailure,
  importContactsRequest,
  importContactsSuccess,
} from "../../../features/manager/networkContactDatabaseSlice";

function* importContactsSaga(
  action: PayloadAction<{
    emails: string[];
  }>
): Generator<any, void, any> {
  try {
    const response: ApiResponse<void> = yield call(
      importContacts,
      action.payload
    );

    if (response.status === "success") {
      yield put(importContactsSuccess());
      yield put(getNetworkContactsRequest());
    } else {
      const errorMessage = response.message || "Failed to import contacts.";
      yield put(importContactsFailure(errorMessage));
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || error.message || "An error occurred";
    yield put(importContactsFailure(errorMessage));
  }
}

function* deleteContactsSaga(
  action: PayloadAction<{ emails: string[] }>
): Generator<any, void, any> {
  try {
    const response: ApiResponse<void> = yield call(
      deleteContacts,
      action.payload
    );

    if (response.status === "success") {
      yield put(deleteContactsSuccess());
      yield put(getNetworkContactsRequest());
    } else {
      const errorMessage = response.message || "Failed to delete contacts.";
      yield put(deleteContactsFailure(errorMessage));
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || error.message || "An error occurred";
    yield put(deleteContactsFailure(errorMessage));
  }
}

function* watchImportContactsSaga() {
  yield takeLatest(importContactsRequest.type, importContactsSaga);
  yield takeLatest(deleteContactsRequest.type, deleteContactsSaga);
}

export default watchImportContactsSaga;
