import { FormLabel } from "@mui/joy";
import StyledText from "../text/styled_text";
import usePalette from "../../theme/use_palette";

export const StyledFormLabel: React.FC<{
  children: React.ReactNode;
  fontSize?: number;
  overrideStyles?: React.CSSProperties;
}> = ({ children, overrideStyles = {}, fontSize = 16 }) => {
  const palette = usePalette();
  return (
    <FormLabel
      sx={{
        mt: 0.5,
      }}
      style={overrideStyles && overrideStyles}
    >
      <StyledText
        level="body-md"
        fontSize={fontSize}
        color={palette.charcoal}
        fontWeight={500}
        sx={{
          letterSpacing: "0.5px",
        }}
      >
        {children}
      </StyledText>
    </FormLabel>
  );
};

export const StyledFormLabelWithHelperText: React.FC<{
  fontSize?: number;
  children: React.ReactNode;
}> = ({ children, fontSize }) => {
  const palette = usePalette();
  return (
    <FormLabel>
      <StyledText
        level="body-md"
        fontSize={fontSize ? fontSize : 15}
        color={palette.textSecondary}
        fontWeight={400}
        style={{
          marginTop: 5,
          overflowWrap: "break-word",
          width: "100%",
        }}
      >
        {children}
      </StyledText>
    </FormLabel>
  );
};
