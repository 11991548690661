// src/redux/sagas/networkSettingsSaga.tsx

import { call, put, takeLatest, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { INetworkSettings, INetworkSettingsInput } from "../../../types";
import {
  updateNetworkSettingsRequest,
  updateNetworkSettingsSuccess,
  updateNetworkSettingsFailure,
  updateUploadProgress,
  uploadBannerRequest,
  uploadBannerSuccess,
  uploadBannerFailure,
  deleteBannerRequest,
  deleteBannerSuccess,
  deleteBannerFailure,
} from "../../features/networkSettingsSlice";
import {
  ApiResponse,
  deleteApi,
  postApi,
  putApi,
} from "../../../utils/api/api";
import axios, { AxiosProgressEvent } from "axios";
import { RootState } from "../../../store";
import { toast } from "react-toastify";
import ApiRoutes from "../../../routes/backend_routes";

// Selector to get the network ID from the state
const getNetworkId = (state: RootState) => state.network.network?.id;

function* updateNetworkSettingsSaga(
  action: PayloadAction<INetworkSettingsInput>
): Generator<any, void, any> {
  try {
    const response: ApiResponse<{
      settings: INetworkSettings;
    }> = yield call(
      putApi,
      `/manager/network/settings`,
      action.payload,
      true,
      false
    );

    if (response.status === "success") {
      yield put(updateNetworkSettingsSuccess(response.data.settings));
      toast.success("Network settings updated successfully");
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || error.message || "An error occurred";
    yield put(updateNetworkSettingsFailure(errorMessage));
  }
}

function uploadBannerApi(
  file: File,
  progressCallback: (progress: number) => void
) {
  const formData = new FormData();
  formData.append("banner", file);

  return axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/manager/network/settings/banner`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          progressCallback(percentCompleted);
        }
      },
    }
  );
}

function* uploadBannerSaga(
  action: PayloadAction<File>
): Generator<any, void, any> {
  const file = action.payload;
  try {
    // Check image dimensions
    const dimensions = yield new Promise<{ width: number; height: number }>(
      (resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = reject;
          img.src = event.target?.result as string;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      }
    );

    // Proceed with the upload if dimensions are valid
    const formData = new FormData();
    formData.append("banner", file);

    const response: ApiResponse<{ banner_url: string }> = yield call(
      postApi,
      ApiRoutes.MANAGER_NETWORK_SETTINGS_BANNER,
      formData,
      true,
      true,
      (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          put(updateUploadProgress(percentCompleted));
        }
      }
    );

    if (response.status === "success") {
      yield put(uploadBannerSuccess(response.data.banner_url));
      toast.success("Banner uploaded successfully");
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || error.message || "An error occurred";
    toast.error(errorMessage);
    yield put(uploadBannerFailure(errorMessage));
  }
}

function* deleteBannerSaga(): Generator<any, void, any> {
  try {
    const response: ApiResponse<null> = yield call(
      deleteApi,
      ApiRoutes.MANAGER_NETWORK_SETTINGS_BANNER,
      {},
      true,
      true
    );

    if (response.status === "success") {
      yield put(deleteBannerSuccess());
    } else {
      const errorMessage =
        response.message || "Failed to delete network settings.";
      yield put(deleteBannerFailure(errorMessage));
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || error.message || "An error occurred";
    yield put(deleteBannerFailure(errorMessage));
  }
}

function* watchNetworkSettingsSaga() {
  yield takeLatest(
    updateNetworkSettingsRequest.type,
    updateNetworkSettingsSaga
  );
  yield takeLatest(deleteBannerRequest.type, deleteBannerSaga);
  yield takeLatest(uploadBannerRequest.type, uploadBannerSaga);
}

export default watchNetworkSettingsSaga;
