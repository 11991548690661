import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorState, IMerchantCustomization } from "../../../../types";

interface MerchantCustomizationState {
  customization: IMerchantCustomization;
  loading: boolean;
  error: ErrorState;
}

const initialState: MerchantCustomizationState = {
  customization: {
    backgroundColor: "",
    brandColor: "",
    accentColor: "",
    rectShape: "",
    fontType: "",
  },
  loading: false,
  error: null,
};

const merchantCustomizationSlice = createSlice({
  name: "merchantCustomization",
  initialState,
  reducers: {
    fetchMerchantCustomizationStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchMerchantCustomizationSuccess: (
      state,
      action: PayloadAction<IMerchantCustomization>
    ) => {
      state.customization = action.payload;
      state.loading = false;
    },
    fetchMerchantCustomizationFailure: (
      state,
      action: PayloadAction<ErrorState>
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateMerchantCustomizationStart: (
      state,
      action: PayloadAction<{
        data: IMerchantCustomization;
        logoFile?: File | null;
        iconFile?: File | null;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    updateMerchantCustomizationSuccess: (
      state,
      action: PayloadAction<IMerchantCustomization>
    ) => {
      state.customization = action.payload;
      state.loading = false;
    },
    updateMerchantCustomizationFailure: (
      state,
      action: PayloadAction<ErrorState>
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchMerchantCustomizationStart,
  fetchMerchantCustomizationSuccess,
  fetchMerchantCustomizationFailure,
  updateMerchantCustomizationStart,
  updateMerchantCustomizationSuccess,
  updateMerchantCustomizationFailure,
} = merchantCustomizationSlice.actions;

export default merchantCustomizationSlice.reducer;
