import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/joy";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import MessageBubble from "../message_bubble";
import TypingIndicator from "../typing_indicator";
import WelcomeMessage from "../welcome_message";
import usePalette from "../../../../theme/use_palette";

const SidebarChatMessages: React.FC = () => {
  const palette = usePalette();
  const { currentSession, processing } = useSelector(
    (state: RootState) => state.aiChat
  );

  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const sortedMessages = currentSession?.messages
    ? [...currentSession.messages].sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      )
    : [];

  useEffect(() => {
    scrollToBottom();
  }, [currentSession?.messages?.length, processing]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const handleScroll = () => {
    if (!containerRef.current) return;
    const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
    setShowScrollButton(distanceFromBottom > 500);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        height: "100%",
        overflowY: "auto",
        pr: 1,
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: palette.greyLight,
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: palette.charcoalTransparent,
        },
      }}
    >
      {sortedMessages.length === 0 && <WelcomeMessage />}

      {sortedMessages.map((message, index) => (
        <MessageBubble key={message.id} message={message} />
      ))}

      {processing && <TypingIndicator />}
    </Box>
  );
};

export default SidebarChatMessages;
