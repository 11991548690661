// usePalette.tsx
import { useThemeContext } from "./theme_context";

const usePalette = () => {
  const { PALLETTE } = useThemeContext();
  return PALLETTE;
};

const useIsDarkTheme = () => {
  const { theme } = useThemeContext();
  return theme === "dark";
};

export { useIsDarkTheme };
export default usePalette;
