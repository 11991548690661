import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IChatRequest,
  IAIChatSession,
  IAIChatSessionMessage,
  AIChatResponse,
} from "../../../../types";

interface AIChatState {
  sessions: IAIChatSession[];
  currentSession: IAIChatSession | null;
  eventData: any | null;
  loading: boolean;
  processing: boolean;
  error: string | null;
}

const initialState: AIChatState = {
  sessions: [],
  currentSession: null,
  eventData: null,
  processing: false,
  loading: false,
  error: null,
};

const aiChatSlice = createSlice({
  name: "aiChat",
  initialState,
  reducers: {
    // Send message
    sendMessageRequest: (state, action: PayloadAction<IChatRequest>) => {
      state.loading = true;
      state.processing = true;
      state.error = null;
    },
    sendMessageSuccess: (state, action: PayloadAction<AIChatResponse>) => {
      state.loading = false;
      state.processing = false;
      state.currentSession = action.payload.session;
    },
    sendMessageFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.processing = false;
    },

    // Fetch sessions
    fetchSessionsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchSessionsSuccess: (state, action: PayloadAction<IAIChatSession[]>) => {
      state.loading = false;
      state.sessions = action.payload;
    },
    fetchSessionsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Set current session
    setCurrentSession: (
      state,
      action: PayloadAction<IAIChatSession | null>
    ) => {
      state.currentSession = action.payload;
    },
    clearCurrentSession: (state) => {
      state.currentSession = null;
    },
  },
});

export const {
  sendMessageRequest,
  sendMessageSuccess,
  sendMessageFailure,
  fetchSessionsRequest,
  fetchSessionsSuccess,
  fetchSessionsFailure,
  setCurrentSession,
  clearCurrentSession,
} = aiChatSlice.actions;

export default aiChatSlice.reducer;
