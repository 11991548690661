// Define the interface for the route parameters
interface GenerateRouteParams {
  eventID?: number | string;
  organizationID?: number | string;
  ticketRequestID?: number | string;
  ticketID?: number | string;
  ticketReleaseID?: number | string;
  networkID?: number | string;
  token?: string;
  templateID?: number | string;
  networkSlug?: string;
  ticketTypeID?: number | string;
  sessionID?: number | string;
}

// Define the API routes class
class ApiRoutes {
  // Base URL from environment variable
  public static BASE_URL: string = process.env.REACT_APP_BACKEND_URL || "";
  public static BASE_URL_NO_PROTOCOL: string = ApiRoutes.BASE_URL.replace(
    /^https?:\/\//,
    ""
  );

  static BOOK_DEMO = `${ApiRoutes.BASE_URL}/book-demo`;

  static EVENT_INTEREST = `${ApiRoutes.BASE_URL}/event-interest/:eventID`;

  // Static properties for API endpoints
  static VIEW_EVENT_LANDING_PAGE = `${ApiRoutes.BASE_URL}/view/events/:refID/landing-page`;
  static MANAGER_EVENTS = `${ApiRoutes.BASE_URL}/manager/events`;
  static MANAGER_EVENT = `${ApiRoutes.BASE_URL}/manager/events/:eventID`;
  static MANAGER_COMPLETE_EVENT_WORKFLOW = `${ApiRoutes.BASE_URL}/manager/complete-event-workflow`;
  static MANAGER_EVENT_TICKET_RELEASE = `${ApiRoutes.BASE_URL}/manager/events/:eventID/ticket-release`;
  static MANAGER_NETWORK = `${ApiRoutes.BASE_URL}/manager/network`;
  static MANAGER_EVENT_TICKET_CHECK_IN = `${ApiRoutes.BASE_URL}/manager/events/:eventID/tickets/qr-check-in`;
  static MANAGER_EVENT_SALES_REPORT = `${ApiRoutes.BASE_URL}/manager/events/:eventID/sales-report`;
  static MANAGER_EVENT_SEND_OUT = `${ApiRoutes.BASE_URL}/manager/events/:eventID/send-outs`;
  static MANAGER_ORGANIZATIONS = `${ApiRoutes.BASE_URL}/manager/organizations`;
  static MANAGER_EVENT_LANDING_PAGE = `${ApiRoutes.BASE_URL}/manager/events/:eventID/landing-page`;
  static MANAGER_EVENT_SEATING_PLAN = `${ApiRoutes.BASE_URL}/manager/events/:eventID/seating-plan`;
  static MANAGER_EVENT_LANDING_PAGE_EDITOR = `${ApiRoutes.BASE_URL}/manager/events/:eventID/landing-page/editor`;
  static MANAGER_EVENT_LANDING_PAGE_SET_ENABLED = `${ApiRoutes.BASE_URL}/manager/events/:eventID/landing-page/set-enabled`;
  static MANAGER_NETWORK_MERCHANT = `${ApiRoutes.BASE_URL}/manager/network/merchant`;
  static MANAGER_HANDLE_NETWORK_USERS = `${ApiRoutes.BASE_URL}/manager/network/users`;
  static MANAGER_NETWORK_INVITES = `${ApiRoutes.BASE_URL}/manager/network/users/invites`;
  static MANAGER_EVENT_DETAILS = `${ApiRoutes.BASE_URL}/manager/events/:eventID/details`;
  static MANAGER_CONTACT_DATABASE = `${ApiRoutes.BASE_URL}/manager/contact-database`;
  static MANAGER_CONTACT_DATABASE_IMPORT = `${ApiRoutes.BASE_URL}/manager/contact-database/import`;
  static MANAGER_NETWORK_SETTINGS = `${ApiRoutes.BASE_URL}/manager/network/settings`;
  static MANAGER_NETWORK_SETTINGS_BANNER = `${ApiRoutes.BASE_URL}/manager/network/settings/banner`;
  static MANAGER_EVENT_BANNER = `${ApiRoutes.BASE_URL}/manager/events/:eventID/banner`;
  static MANAFGER_NETWORK_SOCIAL_MEDIA_UPSERT = `${ApiRoutes.BASE_URL}/manager/network/social-media`;

  static MANAGER_OVERVIEW_SITE_VISITS = `${ApiRoutes.BASE_URL}/manager/events/:eventID/overview`;
  static MANAGER_EVENT_TICKETS = `${ApiRoutes.BASE_URL}/manager/events/:eventID/tickets`;
  static MANAGER_EVENT_SECRET_TOKEN = `${ApiRoutes.BASE_URL}/manager/events/:eventID/manage/secret-token`;
  static MANAGER_EVENT_CAN_MANAGE = `${ApiRoutes.BASE_URL}/manager/events/:eventID/manage`;

  static MANAGER_MERCHANT_CUSTOMIZATION = `${ApiRoutes.BASE_URL}/manager/merchant/customize`;
  static MANAGER_MERCHANT_CUSTOMIZATION_LOGO = `${ApiRoutes.BASE_URL}/manager/merchant/customize/logo`;
  static MANAGER_MERCHANT_CUSTOMIZATION_ICON = `${ApiRoutes.BASE_URL}/manager/merchant/customize/icon`;
  // Accept network invite
  static ACCEPT_NETWORK_INVITE = `${ApiRoutes.BASE_URL}/network/accept-invite/:token`;

  // Manager ticket requests
  static MANAGER_EVENT_TICKET_REQEUST_ACTION = `${ApiRoutes.BASE_URL}/manager/events/:eventID/ticket-requests/action`;

  // Manager ticket
  static MANAGER_EVENT_TICKET_ACTION = `${ApiRoutes.BASE_URL}/manager/events/:eventID/tickets/action`;

  // Event statistics
  static MANAGER_EVENT_STATISTICS = `${ApiRoutes.BASE_URL}/manager/events/:eventID/statistics`;

  // Event email templates
  static MANAGER_EVENT_EMAIL_TEMPLATES = `${ApiRoutes.BASE_URL}/manager/events/:eventID/email-templates`;
  static MANAGER_EVENT_EMAIL_TEMPLATES_UPDATE = `${ApiRoutes.BASE_URL}/manager/events/:eventID/email-templates/:templateID`;

  static MANAGER_SEND_EVENT_EMAILS = `${ApiRoutes.BASE_URL}/manager/events/:eventID/send-event-email`;

  static TEMPLATE_TICKET_RELEASE_UNSAVE = `${ApiRoutes.BASE_URL}/templates/ticket-release/:ticketReleaseID/unsave`;
  static TEMPLATE_TICKET_RELEASES = `${ApiRoutes.BASE_URL}/templates/ticket-release`;

  static TEMPLATE_TICKET_TYPES_UNSAVE = `${ApiRoutes.BASE_URL}/templates/ticket-types/:ticketTypeID/unsave`;
  static TEMPLATE_TICKET_TYPES = `${ApiRoutes.BASE_URL}/templates/ticket-types`;

  static ORGANIZATION = `${ApiRoutes.BASE_URL}/organizations/:organizationID`;
  static ORGANIZATION_STORE_CUSTOMIZATION = `${ApiRoutes.BASE_URL}/manager/organizations/:organizationID/store/customize`;

  // Oauth
  static OAUTH_GOOGLE = `${ApiRoutes.BASE_URL}/oauth/google`;

  // Business page
  static NETWORK_BUSINESS_PAGE = `${ApiRoutes.BASE_URL}/network-business-page/:networkSlug`;

  // Unsuscribe
  static UNSUBSCRIBE = `${ApiRoutes.BASE_URL}/contact/unsubscribe`;

  // Profile
  static PROFILE_NAME = `${ApiRoutes.BASE_URL}/profile`;
  static PROFILE_DEACTIVATE = `${ApiRoutes.BASE_URL}/profile/deactivate`;

  static MANAGER_AI_SESSIONS = `${ApiRoutes.BASE_URL}/manager/ai/sessions`;
  static MANAGER_AI_SESSION_NAME = `${ApiRoutes.BASE_URL}/manager/ai/sessions/:sessionID/name`;
  static MANAGER_AI_CHAT = `${ApiRoutes.BASE_URL}/manager/ai/chat`;

  // Method to generate routes with dynamic parameters
  static generateRoute(route: string, params: GenerateRouteParams): string {
    let result = route;
    if (params.eventID) {
      result = result.replace(":eventID", params.eventID.toString());
    }
    if (params.organizationID) {
      result = result.replace(
        ":organizationID",
        params.organizationID.toString()
      );
    }
    if (params.ticketRequestID) {
      result = result.replace(
        ":ticketRequestID",
        params.ticketRequestID.toString()
      );
    }
    if (params.ticketID) {
      result = result.replace(":ticketID", params.ticketID.toString());
    }
    if (params.ticketReleaseID) {
      result = result.replace(
        ":ticketReleaseID",
        params.ticketReleaseID.toString()
      );
    }
    if (params.networkID) {
      result = result.replace(":networkID", params.networkID.toString());
    }
    if (params.token) {
      result = result.replace(":token", params.token.toString());
    }
    if (params.templateID) {
      result = result.replace(":templateID", params.templateID.toString());
    }

    if (params.networkSlug) {
      result = result.replace(":networkSlug", params.networkSlug.toString());
    }

    if (params.ticketTypeID) {
      result = result.replace(":ticketTypeID", params.ticketTypeID.toString());
    }

    if (params.sessionID) {
      result = result.replace(":sessionID", params.sessionID.toString());
    }

    return result;
  }
}

export default ApiRoutes;
