// src/contexts/EventContext.tsx

import React, { createContext, useState, useContext, ReactNode } from "react";
import { IEvent } from "../types";

interface EventContextType {
  event: IEvent | null;
  setEvent: React.Dispatch<React.SetStateAction<IEvent | null>>;
}

const EventContext = createContext<EventContextType | undefined>(undefined);

export const EventProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [event, setEvent] = useState<IEvent | null>(null);

  return (
    <EventContext.Provider value={{ event, setEvent }}>
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = (): EventContextType => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error("useEvent must be used within an EventProvider");
  }
  return context;
};
