import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import { INetworkInvite } from "../../../types";

import { toast } from "react-toastify";
import { ApiResponse, fetchApi } from "../../../utils/api/api";
import {
  getNetworkInvitesFailure,
  getNetworkInvitesRequest,
  getNetworkInvitesSuccess,
  removeNetworkInviteFailure,
  removeNetworkInviteRequest,
  removeNetworkInviteSuccess,
} from "../../features/manager/networkInviteSlice";
import ApiRoutes from "../../../routes/backend_routes";

function* getNetworkInvitesSaga(
  action: PayloadAction<{
    networkID: number;
  }>
): Generator<any, void, any> {
  try {
    const { networkID } = action.payload;
    const url = ApiRoutes.generateRoute(ApiRoutes.MANAGER_NETWORK_INVITES, {
      networkID: networkID,
    });

    const response: ApiResponse<{ invites: INetworkInvite[] }> = yield call(
      fetchApi,
      url,
      true,
      true
    );

    if (response.status === "success") {
      yield put(getNetworkInvitesSuccess(response.data.invites));
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || "An error occurred";
    toast.error(errorMessage);
    yield put(
      getNetworkInvitesFailure({
        status: error.response?.status,
        message: errorMessage,
      })
    );
  }
}

function* removeNetworkInviteSaga(
  action: PayloadAction<{ token: string }>
): Generator<any, void, any> {
  try {
    const url = ApiRoutes.generateRoute(ApiRoutes.MANAGER_NETWORK_INVITES, {
      token: action.payload.token,
    });

    const response = yield call(axios.delete, url, {
      data: { token: action.payload.token },
      withCredentials: true,
    });

    if (response.status === 200) {
      yield put(removeNetworkInviteSuccess(action.payload.token));
      toast.success("Invite removed successfully");
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || "An error occurred";
    toast.error(errorMessage);
    yield put(
      removeNetworkInviteFailure({
        status: error.response?.status,
        message: errorMessage,
      })
    );
  }
}

function* watchNetworkInvitesSaga() {
  yield takeLatest(getNetworkInvitesRequest.type, getNetworkInvitesSaga);
  yield takeLatest(removeNetworkInviteRequest.type, removeNetworkInviteSaga);
}

export default watchNetworkInvitesSaga;
