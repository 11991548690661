import ApiRoutes from "../../../../routes/backend_routes";
import { IAIChatSession } from "../../../../types";
import { ApiResponse, putApi } from "../../../../utils/api/api";

export const updateSessionName = async (
  sessionId: number,
  name: string
): Promise<IAIChatSession> => {
  try {
    const response: ApiResponse<{ session: IAIChatSession }> = await putApi(
      ApiRoutes.generateRoute(ApiRoutes.MANAGER_AI_SESSION_NAME, {
        sessionID: sessionId,
      }),
      { name },
      true,
      true
    );

    return response.data.session;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || "Failed to update session name";
    throw new Error(errorMessage);
  }
};
