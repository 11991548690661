import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getSubdomain } from "../utils/subdomain/subdomain_utils";
import FourOFour404 from "../pages/errors/404";
import { EventDetail } from "./page_import";
import { useDispatch } from "react-redux";
import { currentUserRequest } from "../redux/features/userSlice";
import { AppDispatch } from "../store";
import EventLandingPage from "../pages/event/detail/detail_landing_page";

const SubdomainHandler: React.FC = () => {
  const subdomain = getSubdomain();
  const dispatch: AppDispatch = useDispatch();
  const [hasLandingPage, setHasLandingPage] = React.useState<boolean>(false);
  const location = useLocation();
  const is404Page = location.pathname === "/404";

  useEffect(() => {
    if (subdomain && !is404Page) {
      // Only dispatch request if not on 404 page
      dispatch(currentUserRequest());
    }
  }, [subdomain, dispatch, is404Page]);

  const triggerLandingPage = () => {
    setHasLandingPage(true);
  };

  if (is404Page) {
    window.location.assign(process.env.REACT_APP_BASE_URL + "/404");
    return null;
  }

  if (subdomain) {
    if (hasLandingPage) {
      return <EventLandingPage subdomain={subdomain} />;
    }

    return (
      <EventDetail
        subdomain={subdomain}
        triggerLandingPage={triggerLandingPage}
      />
    );
  }

  return <Outlet />;
};

export default SubdomainHandler;
