// src/components/manager/ai/SessionsMenu.tsx

import React from "react";
import { Menu, MenuItem } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../store";
import { IAIChatSession } from "../../../../types";
import { setCurrentSession } from "../../../../redux/features/manager/ai/aiChatSlice";

const SessionsMenu: React.FC = () => {
  const dispatch = useDispatch();
  const { sessions, currentSession } = useSelector(
    (state: RootState) => state.aiChat
  );
  const { t } = useTranslation();

  return (
    <Menu
      sx={{
        zIndex: 2000,
      }}
      size="sm"
    >
      {sessions && sessions.length > 0 ? (
        [...sessions]
          .sort(
            (a, b) =>
              new Date(b.updated_at).getTime() -
              new Date(a.updated_at).getTime()
          )
          .map((session: IAIChatSession) => {
            const eventName =
              session.name ||
              session.messages.find((msg) => msg.data?.event?.name)?.data?.event
                ?.name ||
              "";

            return (
              <MenuItem
                key={session.id}
                onClick={() => dispatch(setCurrentSession(session))}
              >
                {!!eventName ? `${eventName} - ` : ""}{" "}
                {format(new Date(session.created_at), "dd/MM/yyyy HH:mm")}
              </MenuItem>
            );
          })
      ) : (
        <MenuItem disabled>{t("manager.ai.no_sessions")}</MenuItem>
      )}
      <MenuItem onClick={() => dispatch(setCurrentSession(null))}>
        {t("manager.ai.new_chat")}
      </MenuItem>
    </Menu>
  );
};

export default SessionsMenu;
