import { REHYDRATE } from "redux-persist";
import { INetworkContact } from "../../../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface NetworkContactDatabaseState {
  contacts: INetworkContact[];
  loading: boolean;
  error: string | null;
}

const initialState: NetworkContactDatabaseState = {
  contacts: [],
  loading: true,
  error: null,
};

const networkContactDatabaseSlice = createSlice({
  name: "networkContactDatabase",
  initialState,
  reducers: {
    getNetworkContactsRequest: (state) => {
      state.loading = true;
      state.contacts = [];
    },
    getNetworkContactsSuccess: (
      state,
      action: PayloadAction<INetworkContact[]>
    ) => {
      state.loading = false;
      state.contacts = action.payload;
    },
    getNetworkContactsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    importContactsRequest: (
      state,
      action: PayloadAction<{
        emails: string[];
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    importContactsSuccess: (state) => {
      state.loading = false;
    },
    importContactsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      toast.error(action.payload);
    },
    deleteContactsRequest: (
      state,
      action: PayloadAction<{ emails: string[] }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    deleteContactsSuccess: (state) => {
      state.loading = false;
    },
    deleteContactsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      toast.error(action.payload);
    },
    inviteContactsRequest: (
      state,
      action: PayloadAction<{ eventId: number; emails: string[] }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    inviteContactsSuccess: (state) => {
      state.loading = false;
      toast.success("Contacts invited successfully");
    },
    inviteContactsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      toast.error(action.payload);
    },
  },
  extraReducers: {
    // Handle the rehydration action
    [REHYDRATE]: (state, action) => {
      // Customize the rehydrated state as needed
      if (action.payload) {
        return {
          ...state,
          ...action.payload.contacts, // Update this slice only
          loading: false,
        };
      }
      return state;
    },
  },
});

export const {
  getNetworkContactsRequest,
  getNetworkContactsSuccess,
  getNetworkContactsFailure,
  importContactsRequest,
  importContactsSuccess,
  importContactsFailure,
  deleteContactsRequest,
  deleteContactsSuccess,
  deleteContactsFailure,
  inviteContactsRequest,
  inviteContactsSuccess,
  inviteContactsFailure,
} = networkContactDatabaseSlice.actions;

export default networkContactDatabaseSlice.reducer;
