// src/components/forms/FormSelect.tsx
import React from "react";
import { Field, FieldProps } from "formik";
import { FormControl, Select, MenuItem, Tooltip, Grid, Option } from "@mui/joy";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import StyledText from "../text/styled_text";
import usePalette from "../../theme/use_palette";
import {
  StyledFormLabel,
  StyledFormLabelWithHelperText,
} from "../forms/form_labels";
import { StyledErrorMessage } from "../forms/messages";
import { DefaultInputStyle } from "./input_types";

export interface ISelectOption {
  id: string | number;
  value: string | number;
  label: string;
  description?: string;
}

interface FormSelectProps {
  name: string;
  label: string;
  helperText?: string;
  options: ISelectOption[];
  placeholder?: string;
  style?: React.CSSProperties;
  required?: boolean;
  multiple?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  label,
  helperText,
  options,
  placeholder,
  required = false,
  style,
  multiple = false,
}) => {
  const palette = usePalette();
  return (
    <FormControl>
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          return (
            <Select
              required={required}
              {...field}
              multiple={multiple}
              onChange={(_, newValue) => {
                const selectedOption = options.find(
                  (option) => option.value === newValue
                );

                if (selectedOption) {
                  form.setFieldValue(`${name}_label`, selectedOption.label);
                }

                form.setFieldValue(field.name, newValue);
              }}
              style={{
                ...DefaultInputStyle,
                ...style,
              }}
              sx={{
                [`& [aria-expanded="false"]`]: {
                  border: "none !important",
                  backgroundColor: "transparent !important",
                },
              }}
              slotProps={{
                listbox: {
                  sx: {
                    zIndex: 9999, // Adjust this value as needed
                  },
                },
              }}
            >
              {options.map((option) => (
                <Option key={option.id} value={option.value}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    flexDirection={"row"}
                  >
                    <StyledText level="body-sm" color={palette.alwaysBlack}>
                      {option.label}
                    </StyledText>
                    {option.description && (
                      <Tooltip title={option.description}>
                        <HelpOutlineIcon
                          style={{
                            marginLeft: "5px",
                            color: palette.charcoal,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                </Option>
              ))}
            </Select>
          );
        }}
      </Field>
      {helperText && (
        <StyledFormLabelWithHelperText>
          {helperText}
        </StyledFormLabelWithHelperText>
      )}
    </FormControl>
  );
};

export default FormSelect;
