import { Box, CircularProgress } from "@mui/joy";
import { useTranslation } from "react-i18next";
import StyledText from "../text/styled_text";
import usePalette from "../../theme/use_palette";
import { useEffect, useState } from "react";
import ApiRoutes from "../../routes/backend_routes";
import { resendVerificationEmail } from "../../redux/sagas/axios_calls/resend_signup_verification_link";
import { toast } from "react-toastify";
import StyledButton from "../buttons/styled_button";

const WaitingForEmailConfirmationScreen: React.FC<{
  onContinue: () => void;
  user_email: string;
}> = ({ onContinue, user_email }) => {
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const palette = usePalette();

  useEffect(() => {
    if (!user_email) return;

    const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";

    const url = process.env.REACT_APP_BACKEND_URL!;

    // Remove https:// or http:// from the URL
    const strippedUrl = url.replace(/(^\w+:|^)\/\//, "");

    const ws = new WebSocket(
      `${wsProtocol}://${strippedUrl}/ws/email-verification?user_email=${user_email}`
    );

    ws.onmessage = (event) => {
      if (event.data === "verified") {
        ws.send(JSON.stringify({ type: "close" }));
        onContinue();
      }
    };

    ws.onclose = (event) => {};

    ws.onerror = (error) => {};

    // Add a ping to keep the connection alive
    const pingInterval = setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: "ping" }));
      }
    }, 2000); // Ping every 2 seconds

    return () => {
      clearInterval(pingInterval); // Clean up on unmount
      ws.close();
    };
  }, [user_email]);

  const resend = () => {
    setIsButtonDisabled(true);
    resendVerificationEmail(user_email)
      .then((response) => {
        toast.success(response);
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 60000); // Re-enable the button after 60 seconds
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 4,
        textAlign: "center",
      }}
    >
      <StyledText color={palette.primaryMain} level="h2" fontSize={28}>
        {t("customer.wait_verify_email.title")}
      </StyledText>
      <StyledText
        color={palette.charcoal}
        level="body-sm"
        fontSize={18}
        sx={{ mt: 2 }}
      >
        {t("customer.wait_verify_email.description")}
      </StyledText>

      <CircularProgress
        color="primary"
        size={"lg"}
        variant="plain"
        sx={{
          my: 2,
        }}
      />

      <StyledText
        color={palette.charcoal}
        level="body-sm"
        fontSize={18}
        sx={{ mt: 2 }}
      >
        {t("customer.resend.description")}
      </StyledText>
      <StyledButton
        size="sm"
        onClick={resend}
        bgColor={palette.white}
        sx={{ mt: 2 }}
        color={palette.primaryMain}
        disabled={isButtonDisabled}
      >
        {t("customer.resend.toast_button")}
      </StyledButton>
    </Box>
  );
};

export default WaitingForEmailConfirmationScreen;
