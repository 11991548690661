// src/utils/getSubdomain.ts

export function extractDomain(): string {
  const baseUrl = process.env.REACT_APP_BASE_URL!;
  const urlObj = new URL(baseUrl);

  if (urlObj.hostname === "localhost") {
    return urlObj.hostname;
  }

  return urlObj.hostname;
}

export function isIPAddress(hostname: string): boolean {
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipRegex.test(hostname);
}

export function getSubdomain(): string | null {
  const host = window.location.hostname; // e.g., event123.tesseratickets.se
  const domain = "tesseratickets.se";

  if (isIPAddress(host) || host === domain || host === `www.${domain}`) {
    return null; // No subdomain or IP address
  }

  const parts = host.split(".");
  if (parts.length > 2) {
    return parts.slice(0, -2).join("."); // Handles multi-level subdomains if any
  }

  return null;
}

export function IsUsingSubdomain(): boolean {
  return getSubdomain() !== null;
}

export function RemoveSubdomainFromRoute(route: string): string {
  const subdomain = getSubdomain();
  if (subdomain) {
    const regex = new RegExp(`^${subdomain}\\.`);
    return route.replace(regex, "");
  }
  return route;
}

/**
 * Construct a normal route from a route string
 * Returns a string with the base URL and the route
 *
 * @param route
 * @returns
 */
export function ConstructNormalRoute(route: string): string {
  const frontend_url = process.env.REACT_APP_BASE_URL;

  return `${frontend_url}${route}`;
}
