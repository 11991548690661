import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse, fetchApi } from "../../../utils/api/api";
import { INetworkContact } from "../../../types";
import {
  getNetworkContactsRequest,
  getNetworkContactsSuccess,
  getNetworkContactsFailure,
} from "../../features/manager/networkContactDatabaseSlice";
import ApiRoutes from "../../../routes/backend_routes";

function* fetchNetworkContactsSaga(): Generator<any, void, any> {
  try {
    const response: ApiResponse<{ contacts: INetworkContact[] }> = yield call(
      fetchApi,
      ApiRoutes.MANAGER_CONTACT_DATABASE,
      true,
      true
    );

    if (response.status === "success") {
      yield put(getNetworkContactsSuccess(response.data.contacts));
    } else {
      const errorMessage = response.message || "Failed to fetch contacts.";
      yield put(getNetworkContactsFailure(errorMessage));
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || error.message || "An error occurred";
    yield put(getNetworkContactsFailure(errorMessage));
  }
}


function* watchNetworkContactsSaga() {
  yield takeLatest(getNetworkContactsRequest.type, fetchNetworkContactsSaga);
}

export default watchNetworkContactsSaga;
