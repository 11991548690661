// ConfirmSwitchReleaseModal.tsx
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box, Button } from "@mui/joy";
import { useTranslation } from "react-i18next";
import usePalette from "../../../../theme/use_palette";
import { RootState } from "../../../../store";
import {
  cancelSwitchRelease,
  confirmSwitchRelease,
} from "../../../../redux/features/ticketOrderSlice";
import InformationModal from "../../../modal/information";
import StyledText from "../../../text/styled_text";
import StyledButton from "../../../buttons/styled_button";

const ConfirmSwitchReleaseModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isConfirmSwitchModalOpen } = useSelector(
    (state: RootState) => state.ticketOrder
  );

  const { t } = useTranslation();
  const palette = usePalette();

  const handleConfirm = () => {
    dispatch(confirmSwitchRelease());
  };

  const handleCancel = () => {
    dispatch(cancelSwitchRelease());
  };

  return (
    <InformationModal
      isOpen={isConfirmSwitchModalOpen}
      onClose={handleCancel}
      title={t("event.ticket_release.switch_release.title")}
    >
      <Box>
        <StyledText level="body-md" color={palette.charcoal}>
          {t("event.ticket_release.switch_release.confirm")}
        </StyledText>
        <Box mt={2} display="flex" justifyContent="space-between">
          <StyledButton
            size="sm"
            color={palette.alwaysWhite}
            bgColor={palette.errorMain}
            onClick={handleCancel}
          >
            {t("common.no")}
          </StyledButton>
          <StyledButton
            size="sm"
            bgColor={palette.successDark}
            onClick={handleConfirm}
            color={palette.alwaysWhite}
          >
            {t("common.yes")}
          </StyledButton>
        </Box>
      </Box>
    </InformationModal>
  );
};

export default ConfirmSwitchReleaseModal;
