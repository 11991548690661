// src/components/PlanEnrollmentss/PlanEnrollmentsList.tsx
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceInput,
  ReferenceField,
  FunctionField,
  NumberField,
} from "react-admin";

export const PlanEnrollmentsList = (props: any) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="reference_name" />
      <ReferenceField
        source="creator_id"
        reference="users" // Remove the .id since reference should be the resource name
        label="Creator"
      >
        <TextField source="first_name" /> // Add a child field to display
      </ReferenceField>
      <FunctionField
        label="Creator"
        render={(record: any) => `${record.creator_id}`}
      />

      <ReferenceField
        source="package_tier_id"
        reference="package-tiers"
        label="Package"
      >
        <TextField source="name" />
      </ReferenceField>

      <NumberField
        source="one_time_price"
        options={{
          style: "currency",
          currency: "SEK",
        }}
      />
      <NumberField
        source="monthly_price"
        options={{
          style: "currency",
          currency: "SEK",
        }}
      />
      <NumberField
        source="yearly_price"
        options={{
          style: "currency",
          currency: "SEK",
        }}
      />
      <TextField source="plan" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
