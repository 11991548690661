import { Box, Stack } from "@mui/joy";
import usePalette, { useIsDarkTheme } from "../../../theme/use_palette";
import { useEffect, useState } from "react";
import { ITicketRelease } from "../../../types";
import { intervalToDuration } from "date-fns";
import { useTranslation } from "react-i18next";
import StyledText from "../../text/styled_text";

interface TimeUnitProps {
  value: number;
  label: string;
  isCustomStyle?: boolean;
}

const TimeUnit: React.FC<TimeUnitProps> = ({
  value,
  label,
  isCustomStyle = false,
}) => {
  const palette = usePalette();
  const darkMode = useIsDarkTheme();

  if (!isCustomStyle) {
    return (
      <Box sx={{ display: "inline", mx: 1 }}>
        <StyledText
          level="body-lg"
          fontSize={18}
          fontWeight={600}
          color={palette.textSecondary}
          sx={{ display: "inline" }}
        >
          {`${value} ${label}`}
        </StyledText>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1,
        minWidth: "80px",
        background: !darkMode
          ? palette.primaryExtraLight
          : palette.primaryExtraDark,
        borderRadius: "12px",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      }}
    >
      <StyledText
        level="h3"
        fontSize={28}
        fontWeight={700}
        color={palette.primaryDark}
        sx={{ mb: 0.5 }}
      >
        {String(value).padStart(2, "0")}
      </StyledText>
      <StyledText
        level="body-sm"
        fontSize={14}
        color={palette.black}
        sx={{ textTransform: "uppercase" }}
      >
        {label}
      </StyledText>
    </Box>
  );
};

const TicketReleaseCountdown: React.FC<{
  ticketRelease: ITicketRelease;
  fw: number;
  fs: number;
  useOpen?: boolean;
}> = ({ ticketRelease, useOpen }) => {
  const palette = usePalette();
  const { t } = useTranslation();
  const openDate = new Date(ticketRelease.open);
  const closeDate = new Date(ticketRelease.close);
  const targetDate: Date = useOpen ? openDate : closeDate;

  const calculateDuration = () =>
    intervalToDuration({
      start: new Date(),
      end: targetDate,
    });

  const [duration, setDuration] = useState(calculateDuration());
  const [isOpen, setIsOpen] = useState(useOpen && new Date() >= openDate);

  useEffect(() => {
    if (new Date() >= targetDate) {
      clearInterval(1);
      return;
    }

    const intervalId = setInterval(() => {
      setDuration(calculateDuration());
      setIsOpen(useOpen && new Date() >= openDate);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate, useOpen, openDate]);

  const hasLargerUnit = (
    currentUnit: "days" | "hours" | "minutes" | "seconds"
  ): boolean => {
    switch (currentUnit) {
      case "seconds":
        return (
          duration.minutes! > 0 || duration.hours! > 0 || duration.days! > 0
        );
      case "minutes":
        return duration.hours! > 0 || duration.days! > 0;
      case "hours":
        return duration.days! > 0;
      case "days":
        return false;
      default:
        return false;
    }
  };

  if (isOpen) return null;

  const isOpeningCountdown = useOpen;

  return (
    <Box sx={{ width: "100%", my: 1 }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ flexWrap: "wrap", gap: 2 }}
      >
        {duration.days! > 0 && (
          <TimeUnit
            value={duration.days!}
            label={t("event.ticket_release.day")}
            isCustomStyle={isOpeningCountdown}
          />
        )}
        {(duration.hours! > 0 || hasLargerUnit("hours")) && (
          <TimeUnit
            value={duration.hours!}
            label={t("event.ticket_release.hour")}
            isCustomStyle={isOpeningCountdown}
          />
        )}
        {(duration.minutes! > 0 || hasLargerUnit("minutes")) && (
          <TimeUnit
            value={duration.minutes!}
            label={t("event.ticket_release.minute")}
            isCustomStyle={isOpeningCountdown}
          />
        )}
        {(duration.seconds! > 0 || hasLargerUnit("seconds")) && (
          <TimeUnit
            value={duration.seconds!}
            label={t("event.ticket_release.second")}
            isCustomStyle={isOpeningCountdown}
          />
        )}
      </Stack>
    </Box>
  );
};

export default TicketReleaseCountdown;
