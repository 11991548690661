import { lightPaletteExported } from "../../theme/theme_context";
import usePalette from "../../theme/use_palette";
import { INetwork } from "../../types";

export const getNetworkColors = (network?: INetwork | null) => {
  // Default values
  const defaults = {
    main_color: lightPaletteExported.primaryMain,
    accent_color: lightPaletteExported.charcoal,
    text_color: lightPaletteExported.charcoal,
  };

  // Determine main and accent colors based on network settings or use defaults
  const main_color = network?.settings?.main_color || defaults.main_color;
  const accent_color = network?.settings?.accent_color || defaults.accent_color;

  // Determine text color based on the main color's luminance
  const text_color = isColorDark(main_color)
    ? lightPaletteExported.white
    : lightPaletteExported.charcoal;

  return { main_color, accent_color, text_color };
};

/**
 * Determines if a color is dark based on its luminance.
 * @param color - The color to check, in hexadecimal, RGB, or RGBA format.
 * @returns True if the color is dark, false otherwise.
 * @throws Error if the input is not a valid color format.
 */
export function isColorDark(color: string): boolean {
  if (!color) {
    return false;
  }

  if (color.toUpperCase() === "#E83D84") {
    return false;
  }

  try {
    let r, g, b;

    // Check if the color is in hex format
    if (color.startsWith("#")) {
      // Remove the hash if it exists
      const hex = color.replace(/^#/, "");

      // Check if it's a valid hex color
      if (!/^[0-9A-Fa-f]{6}$/.test(hex)) {
        throw new Error("Invalid hex color format");
      }

      // Parse the hex color
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
    } else if (color.startsWith("rgb")) {
      // Check if the color is in RGB or RGBA format
      const rgbMatch = color.match(
        /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})(?:\s*,\s*(\d*\.?\d+))?\s*\)$/
      );

      if (!rgbMatch) {
        throw new Error("Invalid RGB(A) color format");
      }

      r = parseInt(rgbMatch[1], 10);
      g = parseInt(rgbMatch[2], 10);
      b = parseInt(rgbMatch[3], 10);

      // Ensure RGB values are within the valid range
      if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
        throw new Error("RGB values must be between 0 and 255");
      }
    } else {
      throw new Error("Invalid color format");
    }

    // Calculate luminance using the same formula
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // A luminance of 0.5 is the midpoint, so we consider colors
    // with luminance < 0.5 as dark
    return luminance < 0.5;
  } catch (error) {
    return false;
  }
}

export const adjustTransparency = (color: string, alpha: number): string => {
  // Remove the hash if present
  color = color.replace("#", "");

  // Convert to RGB
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  // Return the color with adjusted transparency
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const darkerColor = (color: string, factor: number): string => {
  // Remove the '#' if present
  color = color.startsWith("#") ? color.slice(1) : color;

  // Makes a color darker by a factor
  const r = Math.max(
    0,
    Math.floor(parseInt(color.substring(0, 2), 16) * factor)
  );
  const g = Math.max(
    0,
    Math.floor(parseInt(color.substring(2, 4), 16) * factor)
  );
  const b = Math.max(
    0,
    Math.floor(parseInt(color.substring(4, 6), 16) * factor)
  );

  // Convert the result to a 2-digit hex and pad with 0 if needed
  const rHex = r.toString(16).padStart(2, "0");
  const gHex = g.toString(16).padStart(2, "0");
  const bHex = b.toString(16).padStart(2, "0");

  // Return the darker color
  return `#${rHex}${gHex}${bHex}`;
};

export const lightenColor = (color: string, factor: number): string => {
  // Remove the '#' if present
  color = color.startsWith("#") ? color.slice(1) : color;

  // Makes a color lighter by a factor
  const r = Math.min(
    255,
    Math.floor(parseInt(color.substring(0, 2), 16) + 255 * factor)
  );
  const g = Math.min(
    255,
    Math.floor(parseInt(color.substring(2, 4), 16) + 255 * factor)
  );
  const b = Math.min(
    255,
    Math.floor(parseInt(color.substring(4, 6), 16) + 255 * factor)
  );

  // Convert the result to a 2-digit hex and pad with 0 if needed
  const rHex = r.toString(16).padStart(2, "0");
  const gHex = g.toString(16).padStart(2, "0");
  const bHex = b.toString(16).padStart(2, "0");

  // Return the lighter color
  return `#${rHex}${gHex}${bHex}`;
};

export const isValidHex = (value: string): boolean => {
  return /^#[0-9A-F]{6}$/i.test(value);
};

export const isValidHex8 = (value: string): boolean => {
  return /^#[0-9A-F]{8}$/i.test(value);
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
