// AuthButtons.tsx
import React from "react";
import { Stack, IconButton, useTheme } from "@mui/joy";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import StyledButton from "../buttons/styled_button";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/def";
import { isColorDark, lightenColor } from "../../utils/manager/color";
import usePalette from "../../theme/use_palette";
import { useMediaQuery } from "@mui/material";
import BookDemoModal from "../main/book_a_demo";
import { useDispatch } from "react-redux";
import { setLoginRedirect } from "../../redux/features/authSlice";
import {
  ConstructNormalRoute,
  IsUsingSubdomain,
} from "../../utils/subdomain/subdomain_utils";

interface AuthButtonsProps {
  mainColor: string;
  textColor: string;
  isEventPage?: boolean;
  showLogin?: boolean;
  showBookDemo?: boolean;
  onClick?: () => void; // For mobile navigation to close the drawer
}

const AuthButtons: React.FC<AuthButtonsProps> = ({
  mainColor,
  textColor,
  isEventPage = false,
  showLogin = true,
  showBookDemo = true,
  onClick,
}) => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch: AppDispatch = useDispatch();
  const usingSubdomain = IsUsingSubdomain();
  const palette = usePalette();

  let iconColor =
    isColorDark(mainColor) && !isMobile ? palette.black : palette.black;

  if (isEventPage) {
    iconColor = palette.black;
  }

  if (isLoggedIn) {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <IconButton
          component="a"
          href={ConstructNormalRoute(ROUTES.PROFILE)}
          onClick={onClick}
          sx={{
            "&:hover": {
              backgroundColor: lightenColor(mainColor, 0.2),
            },
          }}
        >
          <PersonIcon style={{ color: iconColor }} />
        </IconButton>
        <IconButton
          component="a"
          href={ConstructNormalRoute(ROUTES.LOGOUT)}
          onClick={onClick}
          sx={{
            "&:hover": {
              backgroundColor: lightenColor(mainColor, 0.2),
            },
          }}
        >
          <LogoutIcon style={{ color: iconColor }} />
        </IconButton>
      </Stack>
    );
  }

  if (!showLogin) {
    return null;
  }

  return (
    <React.Fragment>
      <StyledButton
        color={textColor}
        size="sm"
        bgColor={palette.primaryMain}
        style={{ margin: "0 16px" }}
        fs={17}
        onClick={() => {
          if (onClick) onClick();
          if (usingSubdomain) {
            dispatch(setLoginRedirect(window.location.href));
            window.location.assign(ConstructNormalRoute(ROUTES.LOGIN));
            return;
          }
          dispatch(setLoginRedirect(window.location.pathname));
          navigate(ROUTES.LOGIN);
        }}
      >
        {t("navigation.login")}
      </StyledButton>
      {showBookDemo && <BookDemoModal buttonSize="sm" fontSize={17} />}
    </React.Fragment>
  );
};

export default AuthButtons;
