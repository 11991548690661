import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";

interface DrawerState {
  isPinned: boolean;
}

const initialState: DrawerState = {
  isPinned: true,
};

const drawerSlice = createSlice({
  name: "drawerPinned",
  initialState,
  reducers: {
    setPinned: (state, action: PayloadAction<boolean>) => {
      state.isPinned = action.payload;
    },
  },
});

export const { setPinned } = drawerSlice.actions;

export default drawerSlice.reducer;
