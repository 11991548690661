// src/store/sagas/axios_calls/contact_database/contacts_service.ts

import ApiRoutes from "../../../../routes/backend_routes";
import { EventEmailTypes } from "../../../../types";
import { ApiResponse, postApi } from "../../../../utils/api/api";

export const inviteContactsToEvent = async (data: {
  eventId: number;
  emails: string[];
}): Promise<ApiResponse<void>> => {
  try {
    const response: ApiResponse<void> = await postApi(
      ApiRoutes.generateRoute(ApiRoutes.MANAGER_SEND_EVENT_EMAILS, {
        eventID: data.eventId,
      }),

      { emails: data.emails, template_type: EventEmailTypes.InviteEmail },
      true,
      true
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
