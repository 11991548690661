import React from "react";
import { ITicketRelease, ITicketType } from "../../../types";
import { Box, Stack } from "@mui/joy";
import usePalette from "../../../theme/use_palette";
import TicketReleaseCountdown from "./tr_countdown";
import { useTranslation } from "react-i18next";
import StyledText from "../../text/styled_text";
import { adjustTransparency } from "../../../utils/manager/color";
import TicketTypePreview from "../ticket_types/ticket_type_preview";
import { format } from "date-fns";

const TicketReleaseHasNotOpened: React.FC<{
  ticketRelease: ITicketRelease;
}> = ({ ticketRelease }) => {
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <>
      <Box my={2}>
        {/* Tickets Preview Section */}
        <Box
          sx={{
            borderRadius: 2,
            p: 2,
            mb: 3,
            backgroundColor: adjustTransparency(palette.white, 0.8),
          }}
        >
          <StyledText
            level="body-lg"
            fontWeight={700}
            color={palette.charcoal}
            sx={{ mb: 1 }}
          >
            {t("event.tickets")} ({ticketRelease.tickets_available})
          </StyledText>

          <Stack direction="row" spacing={2}>
            {ticketRelease.ticket_types?.map((ticketType: ITicketType) => (
              <TicketTypePreview ticketType={ticketType} />
            ))}
          </Stack>
        </Box>

        {/* Countdown Section */}
        <StyledText
          level="body-md"
          fontWeight={500}
          color={palette.textSecondary}
          sx={{
            textAlign: "center",
          }}
        >
          {t("event.ticket_release.tickets_available_in", {
            time: format(
              new Date(ticketRelease.open),
              t("event.ticket_release.tickets_available_in_format")
            ),
          })}
        </StyledText>
        <TicketReleaseCountdown
          ticketRelease={ticketRelease}
          fw={500}
          fs={22}
          useOpen={true}
        />
      </Box>
    </>
  );
};

export default TicketReleaseHasNotOpened;
