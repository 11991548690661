import React from "react";
import { toast } from "react-toastify";
import StyledText from "../text/styled_text";
import StyledButton from "../buttons/styled_button";
import usePalette from "../../theme/use_palette";
import { resendVerificationEmail } from "../../redux/sagas/axios_calls/resend_signup_verification_link";
import { useTranslation } from "react-i18next";

const ResendVerificationLinkToast: React.FC<{
  email: string;
}> = ({ email }) => {
  const palette = usePalette();
  const handleResendVerificationLink = () => {
    toast.dismiss();
    resendVerificationEmail(email)
      .then((response) => {
        toast.success(response);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const { t } = useTranslation();

  return (
    <div>
      <StyledText level="body-lg" fontSize={18} color={palette.charcoal}>
        {t("customer.resend.toast_description")}
      </StyledText>
      <StyledButton size="sm" onClick={handleResendVerificationLink}>
        {t("customer.resend.toast_button")}
      </StyledButton>
    </div>
  );
};

export default ResendVerificationLinkToast;
