import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  getNetworkBusinessPageRequest,
  getNetworkBusinessPageSuccess,
  getNetworkBusinessPageFailure,
} from "../features/networkBusinessPageSlice";
import { ApiResponse, fetchApi } from "../../utils/api/api";
import { IEvent, INetwork } from "../../types";
import ApiRoutes from "../../routes/backend_routes";

function* fetchNetworkBusinessPageSaga(
  action: PayloadAction<string>
): Generator<any, void, any> {
  try {
    const response: ApiResponse<{ network: INetwork; events: IEvent[] }> =
      yield call(
        fetchApi,
        ApiRoutes.generateRoute(ApiRoutes.NETWORK_BUSINESS_PAGE, {
          networkSlug: action.payload,
        }),
        true,
        true
      );

    if (response.status === "success") {
      const { network, events } = response.data;
      yield put(getNetworkBusinessPageSuccess({ network, events }));
    } else {
      const errorMessage =
        response.message || "Failed to fetch network business page.";
      yield put(
        getNetworkBusinessPageFailure({
          status: 500,
          message: errorMessage,
        })
      );
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || error.message || "An error occurred";
    yield put(
      getNetworkBusinessPageFailure({
        status: error.response?.status,
        message: errorMessage,
      })
    );
  }
}

function* watchNetworkBusinessPageSaga() {
  yield takeLatest(
    getNetworkBusinessPageRequest.type,
    fetchNetworkBusinessPageSaga
  );
}

export default watchNetworkBusinessPageSaga;
