// src/api/ticketApi.ts

import { toast } from "react-toastify";
import {
  AttendeeFormValues,
  IAttendeeForm,
  IGuestCustomer,
  ITicket,
} from "../../../../types";
import { ApiResponse, putApi } from "../../../../utils/api/api";

interface TicketAttendeeUpdate {
  ticket_id: number;
  attendee: IAttendeeForm;
}

interface UpdateAttendeesRequest {
  tickets: TicketAttendeeUpdate[];
  ticket_order_id: number;
  terms_agreed: boolean;
}

export const updateAttendees = async (
  eventId: number,
  data: UpdateAttendeesRequest,
  guestCustomer: IGuestCustomer | undefined | null
) => {
  const url = !!!guestCustomer
    ? `/events/${eventId}/tickets/attendees`
    : `/guest-customer/${guestCustomer?.id}/tickets/attendees?request_token=${
        guestCustomer!.request_token
      }`;

  try {
    const response: ApiResponse<void> = await putApi(url, data, true, false);

    return response;
  } catch (error: any) {
    toast.error(error.message);
    return error;
  }
};
