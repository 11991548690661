import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventState, IEvent } from "../../types";

const initialState: EventState = {
  event: null,
  loading: false,
  error: null,
  errorStatusCode: null,
  bannerUploadProgress: 0,
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    getEventRequest: (
      state,
      action: PayloadAction<{
        id: number;
        secretToken: string;
        countSiteVisit?: boolean;
        promoCodes?: string[];
      }>
    ) => {
      state.loading = true;
    },
    getEventSuccess: (state, action: PayloadAction<IEvent>) => {
      state.loading = false;
      state.event = action.payload;
    },
    getEventFailure: (
      state,
      action: PayloadAction<{
        error: string;
        errorStatusCode: number;
      }>
    ) => {
      state.loading = false;
      state.error = action.payload.error;
      state.errorStatusCode = action.payload.errorStatusCode;
    },
    uploadEventBannerRequest: (
      state,
      action: PayloadAction<{ eventId: number; file: File }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    uploadEventBannerSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      if (state.event && state.event.details) {
        state.event.details.banner_url = action.payload;
      }
    },
    uploadEventBannerFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateEventBannerUploadProgress: (state, action: PayloadAction<number>) => {
      state.bannerUploadProgress = action.payload;
    },
    deleteEventBannerRequest: (state, action: PayloadAction<number>) => {
      state.loading = true;
      state.error = null;
    },
    deleteEventBannerSuccess: (state) => {
      state.loading = false;
      if (state.event && state.event.details) {
        state.event.details.banner_url = undefined;
      }
    },
    deleteEventBannerFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getEventRequest,
  getEventSuccess,
  getEventFailure,
  uploadEventBannerRequest,
  uploadEventBannerSuccess,
  uploadEventBannerFailure,
  updateEventBannerUploadProgress,
  deleteEventBannerRequest,
  deleteEventBannerSuccess,
  deleteEventBannerFailure,
} = eventSlice.actions;

export default eventSlice.reducer;
