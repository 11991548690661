// src/store/sagas/manager/networkContactDatabaseSaga.ts

import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "../../../../utils/api/api";
import {
  inviteContactsFailure,
  inviteContactsRequest,
  inviteContactsSuccess,
} from "../../../features/manager/networkContactDatabaseSlice";
import { inviteContactsToEvent } from "../../axios_calls/emails/event_email_service";

function* inviteContactsSaga(
  action: PayloadAction<{ eventId: number; emails: string[] }>
): Generator<any, void, any> {
  try {
    const response: ApiResponse<void> = yield call(
      inviteContactsToEvent,
      action.payload
    );

    if (response.status === "success") {
      yield put(inviteContactsSuccess());
      // Optionally refresh contacts or event data if needed
    } else {
      const errorMessage =
        response.message || "Failed to invite contacts to event.";
      yield put(inviteContactsFailure(errorMessage));
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || error.message || "An error occurred";
    yield put(inviteContactsFailure(errorMessage));
  }
}

function* watchEventInviteContactsSaga() {
  yield takeLatest(inviteContactsRequest.type, inviteContactsSaga);
}

export default watchEventInviteContactsSaga;
