import React, { useState } from "react";
import { Box, IconButton } from "@mui/joy";
import ChatIcon from "@mui/icons-material/Chat";
import SidebarContainer from "./sidebar_container";
import usePalette from "../../../../theme/use_palette";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

interface AISidebarActivateProps {
  organizationId?: number;
  eventId?: number;
}

const AISidebarActivate: React.FC<AISidebarActivateProps> = ({
  organizationId,
  eventId,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const palette = usePalette();

  const handleOpenSidebar = () => {
    setSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      {/* Circular button to activate the sidebar */}
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 2000,
        }}
      >
        <IconButton
          onClick={handleOpenSidebar}
          sx={{
            width: 56,
            height: 56,
            borderRadius: "50%",
            backgroundColor: palette.primaryMain,
            boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
            "&:hover": {
              backgroundColor: palette.primaryExtraLight,
            },
          }}
        >
          <AutoAwesomeIcon
            fontSize="large"
            sx={{
              color: palette.white,
            }}
          />
        </IconButton>
      </Box>

      {/* Tessera AI Sidebar */}
      <SidebarContainer
        open={sidebarOpen}
        onClose={handleCloseSidebar}
        organizationId={organizationId}
        eventId={eventId}
      />
    </>
  );
};

export default AISidebarActivate;
